import React from 'react';

import { useAuth } from '../auth/authService';

import Nav from '../../components/Nav';
import SessionList from '../../components/SessionList';
import ClubList from './ClubList';
import Profile from './Profile';
import Programs from './Programs';



const Dashboard = () => {

  const { user } = useAuth();
  const uid = user && user.uid ? user.uid : 'yWSeRBHIVTRuZphaXMGLxaBLvX03';

  return (
    <React.Fragment>
      <div className="fixed top-0 left-0 w-1/2 h-full bg-gray-50" aria-hidden="true"></div>
        <div className="fixed top-0 right-0 w-1/2 h-full bg-gray-50" aria-hidden="true"></div>
        <div className="relative min-h-screen flex flex-col">
          {/* <!-- Navbar --> */}
          <Nav />

          {/* <!-- 3 column wrapper --> */}
          <div className="flex-grow w-full max-w-7xl mx-auto xl:px-8 lg:flex">
            {/* <!-- Left sidebar & main wrapper --> */}
            <div className="flex-1 min-w-0 bg-gray-50 xl:flex">
              <div className=" xl:flex-shrink-0 xl:w-80 bg-gray-50">
                <div className="h-full pl-4 pr-6 py-6 sm:pl-6 lg:pl-8 xl:pl-0">
                  {/* <!-- Start left column area --> */}
                  <div className="h-full relative" style={{ minHeight: '12rem' }}>
                    {/* <div className="absolute inset-0 border-2 border-gray-200 border-dashed rounded-lg"></div> */}
                    <Profile />
                    {/* <ClubList userId="yWSeRBHIVTRuZphaXMGLxaBLvX03" /> */}
                  </div>
                  {/* <!-- End left column area --> */}
                </div>
              </div>

              <div className="lg:min-w-2 lg:flex-1">
                <div className="h-full py-6 px-4 sm:px-6 lg:px-8">
                  {/* <!-- Start main area--> */}
                  <div className="relative h-full" style={{ minHeight: '36rem' }}>
                    <SessionList />
                  </div>
                  {/* <!-- End main area --> */}
                </div>
              </div>
            </div>

            <div className="bg-gray-50 pr-4 sm:pr-6 lg:pr-4 lg:flex-shrink-0 xl:pr-0">
              <div className="h-full pl-6 py-6 lg:w-80">
                {/* <!-- Start right column area --> */}
                <div className="h-full relative" style={{ minHeight: '16rem' }}>
                  {/* <div className="absolute inset-0 border-2 border-gray-200 border-dashed rounded-lg"></div> */}
                  <Programs userId={uid} />
                </div>
                {/* <!-- End right column area --> */}
              </div>
            </div>
          </div>
        </div>
    </React.Fragment>
  )
};

export default Dashboard;