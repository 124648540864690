import React from 'react';
import { useAuth, useProfile } from '../auth/authService';

const SessionDetail = ({ title, detail }) => {
  return (
    <div className="py-5">
      <dt className="text-xs font-medium text-gray-500 truncate">
        {title || ''}
      </dt>
      <dd className="mt-1 text-sm font-semibold text-gray-900">
        {detail || ''}
      </dd>
    </div>
  )
}

const Profile = () => {
  const { user } = useAuth();
  const uid = user && user.uid ? user.uid : 'yWSeRBHIVTRuZphaXMGLxaBLvX03';
  const { profile } = useProfile(uid);
  
  var avatar = '';
  var name = 'Sam Rochester';
  var loc = 'Vancouver';
  var runs = 0;
  var totDistance = 0;
  var score = 0;

  if (profile) {
    
    const { firstName, lastName, imgUrl, location, totalRuns, totalDistance, mas, distance, time } = profile;

    if (firstName) {
      name = `${firstName} ${lastName}`
    }
  
    if (imgUrl) {
      avatar = imgUrl
    }
  
    if (location) {
      loc = location
    }
  
    if (totalRuns) {
      runs = totalRuns
    }
  
    if (totalDistance) {
      totDistance = parseInt(parseInt(totalDistance) / 1000)
    }

    if (mas) {
      score = mas;
    } else {

      var dist = 0;
      var tm = time || 360;

      if (distance) {
        dist = distance
      }

      score = parseFloat(dist / tm).toFixed(2)
      
    }
  }
  
  

  return (
    <div className="bg-white px-4 py-6 shadow sm:p-6 sm:rounded-lg text-center">
      <img className="mx-auto w-10 h-10 bg-gray-300 rounded-full flex-shrink-0" src={avatar} alt=""></img>
      <h3 className="text-lg leading-6 font-medium text-gray-900 mt-4">
        {name}
      </h3>
      <p className="mt-1 text-sm text-gray-500">
        {`${loc} • Rugby`}
      </p>
      <dl className="grid grid-cols-1 gap-3 sm:grid-cols-3">
        <SessionDetail title='Runs' detail={`${runs}`} />
        <SessionDetail title='Distance' detail={`${totDistance}kms`} />
        <SessionDetail title='Mas' detail={`${score}`} />
      </dl>
      
    </div>
  )
}


export default Profile;