import firebase from 'firebase';

var firebaseConfig = {
  apiKey: "AIzaSyCnewEQqRe2rAWx3WbvICFDYAWv3_-xYvQ",
  authDomain: "mas-runner.firebaseapp.com",
  databaseURL: "https://mas-runner.firebaseio.com",
  projectId: "mas-runner",
  storageBucket: "mas-runner.appspot.com",
  messagingSenderId: "509872047096",
  appId: "1:509872047096:web:e73399fc4d09f7e4e48c0d"
};

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth;
export const db = firebase.firestore();

export const Paths = {
  clubMembers: 'club-members',
  clubGroups: 'club-groups',
  athletes: 'athletes',
  activities: 'user-activities',
  programs: 'programs'
};