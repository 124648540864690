import React, { useState, Component } from 'react';
import { Redirect, useHistory } from "react-router-dom";

import { login } from './authService';

import masLogo from '../../assets/mas-logo.svg'



class Login extends Component {

  state = {
    redirect: false,
    email: '',
    password: '',
    from: {},
  }

  componentDidMount() {
    const { location } = this.props;
    console.log(this.props);
    const from = location && location.state && location.state.from ? location.state.from : {};
    
    if (from) {
      this.setState({ from });
    }

  }

  handleChange = (e) => {
    const {id , value} = e.target;
    this.setState(prevState => ({
          ...prevState,
          [id] : value
      }))
  }

  onSubmit = event => {

    event.preventDefault();

    const { email, password } = this.state;
    console.log('submit tapped');
    
    login(email, password, (user, error) => {
      
      if (user) {          
        this.setState({ redirect: true });
      }

      if (error) {
        console.log(error);
      }
    })

  }



  render() {
    
    if (this.state.redirect) {
      const { from } = this.state
      if (from && from.pathname) {
        return <Redirect to={{
          pathname: from.pathname,
          state: {
            data: from.data
          }
        }} />
      }
      return <Redirect to="/dashboard" />
    }
  
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <div>
            <img className="mx-auto h-12 w-auto" src={masLogo} alt="Workflow" />
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              Sign in to your account
            </h2>
          </div>
          <form onSubmit={this.onSubmit} className="mt-8 space-y-6" >
            <input type="hidden" name="remember" value="true" />
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <label htmlFor="email" className="sr-only">Email address</label>
                <input id="email" name="email" type="email" value={this.state.email} onChange={this.handleChange} autoComplete="email" required className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm" placeholder="Email address" />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">Password</label>
                <input id="password" name="password" type="password" value={this.state.password} onChange={this.handleChange} autoComplete="current-password" required className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm" placeholder="Password" />
              </div>
            </div>
  
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input id="remember_me" name="remember_me" type="checkbox" className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded" />
                <label htmlFor="remember_me" className="ml-2 block text-sm text-gray-900">
                  Remember me
                </label>
              </div>
  
              <div className="text-sm">
                <a href="/" className="font-medium text-blue-600 hover:text-blue-500">
                  Forgot your password?
                </a>
              </div>
            </div>
  
            <div>
              <button type="submit" className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                  <svg className="h-5 w-5 text-blue-500 group-hover:text-blue-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fillRule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clipRule="evenodd" />
                  </svg>
                </span>
                Sign in
              </button>
            </div>
          </form>
        </div>
      </div>
    )
  }
  
}



export default Login;
