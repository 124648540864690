import React from 'react';
import ProgramListItem from './ProgramListItem';


const ProgramList = ({ programs }) => {
  const list = programs || [];
  return (
    <ul className="divide-y divide-gray-200">
      {
        list.map((program, idx) => {
          return (
            <li key={idx} className="py-4">
              <ProgramListItem program={program} imgUrl={program.coverImgUrl} title={program.name || ''} details={program.details || ''} />
            </li>
          )
        })
      }
    </ul>
  )
}


export default ProgramList;