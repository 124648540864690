import React, { Component } from 'react';
import {
  useParams
} from "react-router-dom";

import Nav from '../../components/Nav';
import SessionList from '../../components/SessionList';
import ProgramDetailsHeader from './ProgramDetailsHeader';

import { db, Paths } from '../../services/WebService';


class ProgramDetailsPage extends Component {

  state = {
    selectedId: 'leaderboard',
    isJoined: false,
    program: {}
  }

  componentDidMount() {
    /// load program 
    const { id } = this.props.match.params
    console.log(`program id: ${id}`);
    console.log('get program');
    db.collection(Paths.programs).doc(id).get()
      .then(doc => {
        console.log(doc.data());
        this.setState({ program: doc.data() });
      })
    
  }


  didJoinProgram = isJoined => {
    this.setState({ isJoined: !isJoined });
  }



  render() {
    
    const { id } = this.props.match.params
    console.log(`program id: ${id}`);
    const { location } = this.props;
    console.log(this.props);
    const program = location && location.state && location.state.data ? location.state.data : this.state.program;
    console.log(program);

    return (
      <React.Fragment>
        <Nav />
        <ProgramDetailsHeader onJoin={this.didJoinProgram} isJoined={this.state.isJoined} program={program} />
        <div className="bg-gray-100 mt-20 w-full mx-auto px-4 sm:px-6 lg:px-8">
          <div className="pt-10 pb-52 max-w-3xl mx-auto">
            <SessionList />
          </div>
        </div>
      </React.Fragment>
    )
  }



  
}





export default ProgramDetailsPage;