import React, { useContext, useEffect } from 'react';
import firebase from 'firebase';
import { auth } from '../../services/WebService';

const userContext = React.createContext({
  user: null,
});

export const useSession = () => {
  const { user } = useContext(userContext)
  return user
}



export const useAuth = () => {

  const [state, setState] = React.useState(() => { 
    const user = auth().currentUser 
    return { initializing: !user, user, } 
  });

  function onChange(user) {
    setState({ initializing: false, user })
  }

  React.useEffect(() => {
    // listen for auth state changes
    const unsubscribe = auth().onAuthStateChanged(onChange)
    // unsubscribe to the listener when unmounting
    return () => unsubscribe()
  }, []);

  return state
}

export const useProfile = id => {
  // initialize our default state
  const [error, setError] = React.useState(false) 
  const [loading, setLoading] = React.useState(true) 
  const [profile, setProfile] = React.useState(null);
  // when the id attribute changes (including mount)
  // subscribe to the recipe document and update
  // our state when it changes.
  useEffect(
    () => {
      const unsubscribe = firebase.firestore().collection('users').doc(id).onSnapshot(doc => { 
        setLoading(false) 
        setProfile(doc.data()) 
      }, err => { setError(err) } )
      // returning the unsubscribe function will ensure that
      // we unsubscribe from document changes when our id
      // changes to a different value.
      return () => unsubscribe()
    },
    [id]
  )

  return {
    error,
    loading,
    profile,
  }
}






/**
 * 
 * @param {String} email Email
 * @param {String} password Password
 */
export const login = async (email, password, callback) => {

  try {
    const { user } = await firebase.auth().signInWithEmailAndPassword(email, password);
    console.log(user);
    callback(user, null);
  } catch (error) {
    // var errorCode = error.code;
    var errorMessage = error.message;
    console.log(errorMessage);
    callback(null, error);
    
  }
  
}