import React from 'react';
import { Link } from 'react-router-dom';

const ProgramListItem = ({ title, details, imgUrl, program }) => {
  return (
    <div className="flex">
      <div className="mr-4 flex-shrink-0">
        <img className="inline-block h-14 w-14 rounded-md"  src={imgUrl} />
      </div>
      <div>
        <Link to={{
          pathname: `/program/${program.id}`,
          state: {
            data: program
          }
        }}>
          <h4 className="text-lg font-bold">{title ||'' }</h4>
        </Link>
        <p className="mt-1">
          {details || '' }
        </p>
      </div>
    </div>
  )
}


export default ProgramListItem;