import React from 'react';

import { useAuth, useProfile } from '../auth/authService';
import { usePrograms } from './dashboardService';
import MasTestCard from '../../components/MasTestCard';
import ProgramList from '../../components/ProgramList';





const Programs = ({ userId }) => {
  
  const { user } = useAuth();
  const uid = user && user.uid ? user.uid : 'yWSeRBHIVTRuZphaXMGLxaBLvX03';
  const { profile } = useProfile(uid);
  const programIds = profile && profile.programIds ? profile.programIds : [];
  console.log(programIds);
  
  const { programs } = usePrograms(programIds);
  const myPrograms = programs || [];
  
  return (
    <React.Fragment>
      {/* <MasTestCard userId={uid} /> */}
      <h3 className="mt-8 text-lg leading-6 font-medium text-gray-900">My Programs</h3>
      <ProgramList programs={myPrograms} />
    </React.Fragment>
  )
}


export default Programs;