import React, { Component } from 'react';
import moment from 'moment';
import Nav from '../../components/Nav';
import Breadcrumbs from '../../components/Breadcrumbs';
import { Redirect } from 'react-router';
import { saveAthlete, loadGroups } from './addAthleteService';

const DEFAULT_STATE = {
  redirect: false,
  firstName: '',
  lastName: '',
  email: '',
  dob: '',
  groups: [],
  sports: [],
  teams: [],
  selectedGroupIds: [],
  selectedSportIds: [],
  selectedTeamIds: [],
  crumbs: [
    {
      title: 'Club',
      path: '/program/base-conditioning'
    },
    {
      title: 'Add Athlete',
      path: '/add-athlete'
    }
  ]
}

class AddAthlete extends Component {

  state = DEFAULT_STATE
  
  constructor(props) {
    super(props)

    const { location } = this.props;
    const club = location && location.state && location.state.club ? location.state.club : {};
    console.log(club);

    if (club.id) {
      this.state = {
        ...DEFAULT_STATE,
        club,
        crumbs: [
          {
            title: club.name,
            path: `club/${club.id}`,
            data: club
          },
          {
            title: 'Add Athlete',
            path: '/add-athlete'
          }
        ]
      }
    }


  }

  componentDidMount() {
    if (this.state.club) {
      loadGroups(this.state.club.id, allGroups => {
        console.log(allGroups);
        const groups = allGroups.filter(group => group.typeId === 0);
        const sports = allGroups.filter(group => group.typeId === 1);
        const teams = allGroups.filter(group => group.typeId === 2);
        this.setState({ groups, sports, teams });
      })
    }
    
  }

  didSelectGroup = event => {
    const { id , value } = event.target;
    console.log(id);
    console.log(value);
    const key = `selected${id}Ids`
    var newState = {};
    newState[key] = [value]
    this.setState(newState);
  }

  handleChange = (e) => {
    const {id , value} = e.target;
    this.setState(prevState => ({
          ...prevState,
          [id] : value
      }))
  }

  onSubmit = event => {

    event.preventDefault();
    console.log(this.state);
    const { firstName, lastName, dob, email, selectedGroupIds, selectedSportIds, selectedTeamIds, club } = this.state;
    const groupIds = [].concat(selectedGroupIds).concat(selectedSportIds).concat(selectedTeamIds)
    const date = moment(dob).unix();

    const athlete = {
      clubIds: [club.id],
      firstName,
      lastName,
      dob: date,
      email,
      groupIds,
      isClubManaged: true,
      mas: 0,
      distance: 0,
      time: 0
    };

    /// save athlete
    saveAthlete(athlete, club)
      .then(ref => {
        console.log(`successful save: ${ref}`)
        this.setState({ redirect: true });
      })
      .catch(error => alert(`Couldn't saving athlete: ${error.message}`));

  }



  render() {
    const { club } = this.state;
    if (this.state.redirect && club) {

      return <Redirect to={{
        pathname: `/club/${club.id}`,
        state: {
          data: club
        }
      }} />
    }

    return (
      <React.Fragment>
        <Nav />
        <div className="pt-10 pb-40 max-w-7xl mx-auto sm:px-6 lg:px-8 bg-gray-100 ">
        <div className="mb-5 flex-grow w-full max-w-7xl mx-auto xl:px-8 lg:flex">
            <Breadcrumbs items={this.state.crumbs}  />
          </div>
          <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
            <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
              <form >
                <div className="shadow sm:rounded-md sm:overflow-hidden">
                  <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
                    <div>
                      <h3 className="text-lg leading-6 font-medium text-gray-900">Add Athlete</h3>
                      <p className="mt-1 text-sm text-gray-500">This will not create a user. If you would like to give the athlete access to the club please ask them to create an account and join the club</p>
                    </div>

                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6 sm:col-span-3">
                        <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">First name</label>
                        <input value={this.state.firstName} onChange={this.handleChange} type="text" name="firstName" id="firstName" className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm" />
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">Last name</label>
                        <input value={this.state.lastName} onChange={this.handleChange} type="text" name="lastName" id="lastName" className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm" />
                      </div>

                      <div className="col-span-6 sm:col-span-4">
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email address</label>
                        <input value={this.state.email} onChange={this.handleChange} type="text" name="email" id="email" className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm" />
                      </div>

                      <div className="col-span-6 sm:col-span-4">
                        <label htmlFor="dob" className="block text-sm font-medium text-gray-700">Date Of Birth</label>
                        <input value={this.state.dob} onChange={this.handleChange} type="date" name="dob" id="dob"  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm" />
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label htmlFor="Group" className="block text-sm font-medium text-gray-700">Groups</label>
                        <select id="Group" onChange={this.didSelectGroup} name="Group"  className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm" >
                        <option  >Select Group</option>
                          {
                            this.state.groups.map((group, idx) => {
                              return <option key={idx} value={group.id} >{group.name}</option>
                            })
                          }
                        </select>
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label htmlFor="Sport" className="block text-sm font-medium text-gray-700">Sports</label>
                        <select id="Sport" onChange={this.didSelectGroup} name="Sport"  className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm" >
                        <option  >Select Sport</option>
                          {
                            this.state.sports.map((group, idx) => {
                              return <option key={idx} value={group.id} >{group.name}</option>
                            })
                          }
                        </select>
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label htmlFor="Team" className="block text-sm font-medium text-gray-700">Teams</label>
                        <select id="Team" onChange={this.didSelectGroup} name="Team"  className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm" >
                        <option >Select Team</option>
                          {
                            this.state.teams.map((group, idx) => {
                              return <option key={idx} value={group.id} >{group.name}</option>
                            })
                          }
                        </select>
                      </div>

                      
                    </div>
                  </div>
                  <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                    <button onClick={this.onSubmit} type="submit" className="bg-blue-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                      Save
                    </button>
                  </div>
                </div>
              </form>          
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }

}





const GroupType = {
  0: 'Group', 
  1: 'Sport', 
  2: 'Team'
}



export default AddAthlete;