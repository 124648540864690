import React, { useEffect } from 'react';
import { db, Paths } from '../../services/WebService';


export const useClubDetails = clubId => {
  // initialize our default state
  const [error, setError] = React.useState(false) 
  const [loading, setLoading] = React.useState(true) 
  const [members, setMembers] = React.useState(null);

  // when the id attribute changes (including mount)
  // subscribe to the recipe document and update
  // our state when it changes.
  useEffect(
    () => {
      db.collection(Paths.clubMembers).where("clubId", "==", clubId)
        .get()
        .then(snapshot => { 
        
        setLoading(false) 
        
        // console.log();
        var data = [];

        snapshot.forEach(doc => {
          data.push(doc.data());
        });
        
        setMembers(data);

      }, err => { setError(err) } )
      
    },
    [clubId]
  )

  return {
    error,
    loading,
    members,
  }
}



export const useAthletes = clubId => {
  // initialize our default state
  const [error, setError] = React.useState(false) 
  const [loading, setLoading] = React.useState(true) 
  const [members, setMembers] = React.useState(null);

  // when the id attribute changes (including mount)
  // subscribe to the recipe document and update
  // our state when it changes.
  useEffect(
    () => {
      db.collection(Paths.clubMembers).where("clubId", "==", clubId)
        .get()
        .then(snapshot => { 
        
        setLoading(false) 
        
        // console.log();
        var data = [];

        snapshot.forEach(doc => {
          data.push(doc.data());
        });
        
        setMembers(data);

      }, err => { setError(err) } )
      
    },
    [clubId]
  )

  return {
    error,
    loading,
    members,
  }
}

