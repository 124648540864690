import React from 'react';
import { Link } from 'react-router-dom';

const SessionDetail = ({ title, detail }) => {
  return (
    <div className=" py-5 sm:p-6">
      <dt className="text-xs font-medium text-gray-500 truncate">
        {title || ''}
      </dt>
      <dd className="mt-1 text-l font-semibold text-gray-900">
        {detail || ''}
      </dd>
    </div>
  )
}


function distance(interval, mas) {
  let distance = mas * interval.percentage
  let timeInterval = parseFloat(interval.seconds)
  return parseInt(distance * timeInterval)
}

const SessionCard = ({ session, idx }) => {

  const intervals = session && session.intervals ? session.intervals : [{ seconds: 0 }];
  
  const time = intervals.map(item => item.seconds).reduce((prev, next) => {
    return prev + next
  });

  const unit = parseInt(time / 60);
  const mas = 3.8;

  const distance = intervals.map(interval => {
    const distance = mas * interval.percentage
    const timeInterval = parseFloat(interval.seconds)
    return parseInt(distance * timeInterval)
  }).reduce((prev, next) => {
    return prev + next
  })

  const kms = parseFloat(distance / 1000).toFixed(2);
  const percentage = intervals[0].percentage * 100;

  return (
    <div className="bg-white px-4 py-6 shadow sm:p-6 sm:rounded-lg">
      <Link to='/session/base-conditioning-1'>
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Session {idx + 1}
        </h3>
      </Link>      
      <p className="mt-1 text-sm text-gray-500">
        Record your distance for a continuous 6 minute run to build the target distances for your conditioning program
      </p>
      <dl className="grid grid-cols-1 gap-5 sm:grid-cols-3">
        <SessionDetail title='Total Time' detail={`${unit} mins`} />
        <SessionDetail title='Target Distance' detail={`${kms} km`} />
        <SessionDetail title='Loading' detail={`${percentage}% mas`} />
      </dl>
      
    </div>
  )
}


export default SessionCard;