import React from 'react';
import avatar from '../../assets/default_avatar.svg';



const getName = athlete => {
  if (athlete.name) {
    return athlete.name
  }
  return `${athlete.firstName} ${athlete.lastName}`
}

const AthleteListItem = ({ idx, athlete }) => {
  return (
    <tr>
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="flex items-center">
            <div className="text-sm text-gray-500">
              {idx + 1}
            </div>
          
        </div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
          <div className="flex items-center">
            <div>
              <img className="inline-block h-9 w-9 rounded-full" src={athlete.imgUrl || avatar } alt="" />
            </div>
            <div className="ml-3">
              <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">
                {getName(athlete)}
              </p>
              <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">
                View profile
              </p>
            </div>
        </div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
          Active
        </span>
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {`${parseFloat(athlete.mas).toFixed(2)} m/s`}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
        <div className="text-blue-600 hover:text-blue-900">Edit</div>
      </td>
    </tr>
  )
}

export default AthleteListItem;