import React, { useState } from 'react';

const getName = athlete => {
  if (athlete.name) {
    return athlete.name
  }
  return `${athlete.firstName} ${athlete.lastName}`
}

const getMas = athlete => {
  const { time, distance, mas } = athlete;
  if (mas) {
    return parseFloat(mas).toFixed(2)
  }

  if (time && distance) {
    const ms = distance / time;
    return parseFloat(ms).toFixed(2);
  }

  return 0.00
}


const beepToMas = level => {
  return parseFloat(setTest(level)).toFixed(2);
}

const setTest = beepTestLevel => {
  return (((getTestSpeed(beepTestLevel) * 1.34) - 2.86) / 3.6)
}

const getTestSpeed = beepTestLevel => {
  let baseSpeed = 8
  return baseSpeed + (beepTestLevel * 0.5)
}


const Decrease = ({ prev, current }) => {
  const diff = prev - current
  const num = parseFloat(diff / prev * 100).toFixed(1)

  return (
    <div className="ml-2 flex items-baseline text-sm font-semibold text-red-600">
      <svg className="self-center flex-shrink-0 h-5 w-5 text-red-500" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
        <path fillRule="evenodd" d="M14.707 10.293a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L9 12.586V5a1 1 0 012 0v7.586l2.293-2.293a1 1 0 011.414 0z" clipRule="evenodd" />
      </svg>
      <span className="sr-only">
        Decreased by
      </span>
      {num}%
    </div>
  )
}

const Increase = ({ prev, current }) => {
  const diff = current - prev
  const num = parseFloat(diff / prev * 100).toFixed(1)
  return (
    <div className="ml-2 flex items-baseline text-sm font-semibold text-green-600">
      <svg className="self-center flex-shrink-0 h-5 w-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
        <path fillRule="evenodd" d="M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z" clipRule="evenodd" />
      </svg>
      <span className="sr-only">
        Increased by
      </span>
      {num}%
    </div>
  )
}

const renderDiff = (level, isIncrease, mas, athlete) => {
  if (level > 0) {
    return isIncrease ? <Increase prev={athlete.mas} current={mas} /> : <Decrease prev={athlete.mas} current={mas} />
  }
}


const BeepTestTableRow = ({ idx, athlete, didUpdateLevel, newMas }) => {
  const isEven = idx % 2;
  const bgClass = isEven ? 'bg-white' : 'bg-gray-50';

  const [level, setLevel] = useState(athlete.level || '');
  const [mas, setMas] = useState(newMas || '');

  const handleChange = e => {
    const lvl = e.target.value;
    setLevel(lvl);
    const newMas = beepToMas(lvl);
    setMas(newMas);
    didUpdateLevel(athlete, lvl, newMas);
  }
  const isIncrease = mas > athlete.mas;

  return (
    <tr className={bgClass}>
      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
        {getName(athlete)}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {`${getMas(athlete)} m/s`}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        <div className="flex items-baseline">
          {`${mas} m/s`}
          {
             renderDiff(level, isIncrease, mas, athlete)
          }
        </div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        <input value={level} onChange={e => handleChange(e)} type="number" name="level" id="level" className="h-8 shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="Beep test level" />
      </td>
    </tr>
  )
}

export default BeepTestTableRow