import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { db, Paths } from '../../../services/WebService'
import Nav from '../../../components/Nav';
import SelectAthleteRow from './SelectAthleteRow';
import Breadcrumbs from '../../../components/Breadcrumbs';

const DEFAULT_STATE = {
  athletes: [],
  selectedAthletes: [],  
  crumbs: [
    {
      title: 'Club',
      path: '/program/base-conditioning'
    },
    {
      title: 'Test Athletes',
      path: '/add-athlete'
    }
  ]
}


const getAthletes = clubId => {
  return db.collection(Paths.clubMembers).where("clubId", "==", clubId).get()
}



class SelectAthletesContainer extends Component {

  state = DEFAULT_STATE

  

  constructor(props) {
    super(props)

    const { location } = this.props;
    const club = location && location.state && location.state.club ? location.state.club : {};
    console.log(club);

    if (club.id) {
      this.state = {
        ...DEFAULT_STATE,
        club,
        crumbs: [
          {
            title: club.name,
            path: `/club/${club.id}`,
            data: club
          },
          {
            title: 'Add Athlete',
            path: '/add-athlete'
          }
        ]
      }
    }


  }

  componentDidMount() {
    if (this.state.club) {
      getAthletes(this.state.club.id)
        .then(snapshot => {

          const athletes = [];
          snapshot.forEach(doc => athletes.push(doc.data()));
          this.setState({ athletes });

        })
    }
    
  }

  didSelectAthlete = (event, athlete) => {
    if (this.isSelected(athlete)) {
      const selectedAthletes = this.state.selectedAthletes.filter(ath => ath.athleteId !== athlete.athleteId);
      this.setState({ selectedAthletes });
    } else {
      const selectedAthletes = [athlete].concat(this.state.selectedAthletes);
      this.setState({ selectedAthletes });
    }
    console.log(this.state.selectedAthletes);
  }

  isSelected = athlete => {
    return this.state.selectedAthletes.map(item => item.athleteId).includes(athlete.athleteId)
  }

  render() {
    return (
      <React.Fragment>
        <Nav />
        <div className="pt-10 pb-40 max-w-7xl mx-auto sm:px-6 lg:px-8 bg-gray-100 ">
          <div>
            <div className="mb-5 mx-auto lg:flex">
              <Breadcrumbs items={this.state.crumbs}  />
            </div>
            
          </div>
          
          <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Name
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Groups
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          MAS
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Last Tested
                        </th>
                        <th scope="col" className="relative px-6 py-3">
                          <span className="sr-only">Selected</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        this.state.athletes.map((athlete, index) => {
                          const isSelected = this.isSelected(athlete);
                          return <SelectAthleteRow key={index} athlete={athlete} idx={index} isSelected={isSelected} onClick={this.didSelectAthlete} />
                        })
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="my-10">
              <Link to={{
                  pathname:'/beep-test',
                  state: {
                    club: this.state.club,
                    athletes: this.state.selectedAthletes
                  }
                }}>
                  <button type="button" className="inline-flex justify-center px-4 py-2 shadow-sm text-sm font-medium rounded-md text-gray-50 bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                    {/* <!-- Heroicon name: solid/mail --> */}
                    {/* <svg className="-ml-1 mr-2 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                      <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                    </svg> */}
                    <span>Start Beep Test</span>
                  </button>
                </Link>

                <Link to={{
                  pathname:'/bronco-test',
                  state: {
                    club: this.state.club,
                    athletes: this.state.selectedAthletes
                  }
                }}>
                  <button type="button" className="ml-6 inline-flex justify-center px-4 py-2 border border-blue-600 shadow-sm text-sm font-medium rounded-md text-blue-600 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                    {/* <!-- Heroicon name: solid/mail --> */}
                    {/* <svg className="-ml-1 mr-2 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                      <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                    </svg> */}
                    <span>Start Bronco Test</span>
                  </button>
                </Link>
            </div>
          </div>
        </div>
      </React.Fragment>
      
    )
  }

}


export default SelectAthletesContainer