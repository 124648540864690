import { db, Paths } from '../../services/WebService';


export const loadGroups = async (clubId, callback) => {
  /// load club-groups collection
  const snapshot = await db.collection(Paths.clubGroups).where("clubId", "==", clubId).get();
  
  var data = [];

  snapshot.forEach(doc => {
    console.log(doc.data());
    data.push(doc.data());
  });

  callback(data);

};

export const saveAthlete = (athlete, club) => {

  return db.collection(Paths.athletes).add(athlete)
      .then(ref => {
        const clubMember = {
          ...athlete,
          id: `${club.id}-${ref.id}`,
          athleteId: ref.id,
          clubId: club.id,
          clubMemberStateId: 2
        }
        return db.collection(Paths.clubMembers).doc(clubMember.id).set(clubMember)
      })

}