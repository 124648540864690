import React, { Component } from 'react';
import moment from 'moment';
import { Redirect } from 'react-router-dom';

import { db, Paths } from '../../../services/WebService'
import Nav from '../../../components/Nav';
import Breadcrumbs from '../../../components/Breadcrumbs';
import BeepTestTableRow from './BeepTestTableRow';

const DEFAULT_STATE = {
  redirect: false,
  athletes: [],
  updatedScores: {},  
  crumbs: [
    {
      title: 'Club',
      path: '/program/base-conditioning'
    },
    {
      title: 'Test Athletes',
      path: '/add-athlete'
    }
  ]
}



const saveAthletes = (athletes, club, callback) => {
  var saved = 0;
  athletes.forEach(athlete => {
    saveAthlete(athlete, club)
      .then(ref => {
        saved += 1;
        if (saved === athletes.length) {
          callback()
        }
        console.log(`save athlete:`)
        console.log(athlete)
      })
      .catch(error => callback(error.message));
  })
}

const saveAthlete = (athlete, club) => {
  return db.collection(Paths.clubMembers).doc(athlete.id)
            .set(athlete)
            .then(ref => {
              const ath = athlete;
              delete ath.id;
              delete ath.clubMemberStateId;
              delete ath.clubId;
              return db.collection(Paths.athletes).doc(athlete.athleteId).set(ath)
            })
            .then(ref => {
              var activity = {};
              activity.createdAt = moment().unix();
              activity.isComplete = true;
              activity.athleteId = athlete.athleteId || athlete.userId;
              activity.clubId = club.id || '';
              activity.mas = athlete.mas || 0;
              activity.distance = athlete.distance || 0;
              activity.time = athlete.time || 0;
              console.log(activity);
              return db.collection(Paths.activities).add(activity)
            })
}




class BeepTestContainer extends Component {

  state = DEFAULT_STATE


  constructor(props) {
    super(props)

    const { location } = this.props;
    const club = location && location.state && location.state.club ? location.state.club : {};
    const athletes = location && location.state && location.state.athletes ? location.state.athletes : [];
    console.log(club);
    const sorted = athletes.sort((a, b) => {
      var nameA = a.firstName || a.name || ''; // ignore upper and lowercase
      var nameB = b.firstName || b.name || ''; // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    });
    if (club.id) {
      this.state = {
        ...DEFAULT_STATE,
        club,
        athletes: sorted,
        crumbs: [
          {
            title: club.name,
            path: `/club/${club.id}`,
            data: club
          },
          {
            title: 'Add Athletes',
            path: '/add-athlete'
          },
          {
            title: 'Beep Test',
            path: '/beep-test'
          }
        ]
      }
    }


  }

  saveDidTap = () => {

    const newAthletes = this.state.athletes.map(athlete => {
      var ath = athlete;
      const mas = this.state.updatedScores[athlete.athleteId];
      if (mas) {
        ath.mas = mas;
        ath.lastTested = moment().unix();
        ath.masTestType = 1;
      }

      return ath;
    })


    console.log(newAthletes);
    const { club } = this.state;

    if (club) {
      saveAthletes(newAthletes, this.state.club, error => {
        if (error) {
          console.log(error);
          alert(`Couldn't save beep test results: ${error.message}`);
        } else {
          this.setState({ redirect: true });  
        }
      });
    }
    

  }

  levelDidUpdate = (athlete, level, mas) => {
    var scores = this.state.updatedScores;
    scores[athlete.athleteId] = parseFloat(mas);
    this.setState({ updatedScores: scores });
  }

  

  render() {

    const { club } = this.state;
    if (this.state.redirect && club) {

      return <Redirect to={{
        pathname: `/club/${club.id}`,
        state: {
          data: club
        }
      }} />
    }

    return (
      <React.Fragment>
        <Nav />
        <div className="pt-10 pb-40 max-w-7xl mx-auto sm:px-6 lg:px-8 bg-gray-100 ">
          <div>
            <div className="mb-5 flex-grow w-full max-w-7xl mx-auto xl:px-8 lg:flex">
              <Breadcrumbs items={this.state.crumbs}  />
            </div>
          </div>
          
          <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Name
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Previous
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Current
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Level
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        this.state.athletes.map((athlete, index) => {
                          const mas = this.state.updatedScores[athlete.athleteId] || 0;
                          return <BeepTestTableRow newMas={mas} key={index} athlete={athlete} idx={index} didUpdateLevel={this.levelDidUpdate} />
                        })
                      }
                    </tbody>
                  </table>
                </div>
                <div className="my-10">
                  <button onClick={() => this.saveDidTap()} type="button" className="inline-flex justify-center px-4 py-2 shadow-sm text-sm font-medium rounded-md text-gray-50 bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                    {/* <!-- Heroicon name: solid/mail --> */}
                    {/* <svg className="-ml-1 mr-2 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                      <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                    </svg> */}
                    <span>Save</span>
                  </button>  
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )

  }






}


export default BeepTestContainer