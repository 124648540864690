import React from 'react';
import { Link } from "react-router-dom";

import masLogo from '../assets/mas-logo.svg'

const Dropdown = () => {
  return (
    <div className="ml-4 relative flex-shrink-0">
      <div>
        <button type="button" className="bg-blue-700 flex text-sm rounded-full text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue-700 focus:ring-white" id="user-menu" aria-expanded="false" aria-haspopup="true">
          <span className="sr-only">Open user menu</span>
          <img className="h-8 w-8 rounded-full" src={masLogo} alt="" />
        </button>
      </div>

      {/* <!--
        Dropdown menu, show/hide based on menu state.

        Entering: "transition ease-out duration-100"
          From: "transform opacity-0 scale-95"
          To: "transform opacity-100 scale-100"
        Leaving: "transition ease-in duration-75"
          From: "transform opacity-100 scale-100"
          To: "transform opacity-0 scale-95"
      --> */}
      <div className="origin-top-right absolute z-10 right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
        <a href="/" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">View Profile</a>
        <a href="/" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Settings</a>
        <a href="/" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Logout</a>
      </div>
    </div>
  )
}


const Nav = () => {
  return (
    <nav className="flex-shrink-0 bg-gray-800 shadow">
      <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
        <div className="relative flex items-center justify-between h-16">
          {/* <!-- Logo section --> */}
          <div className="flex items-center px-2 lg:px-0 xl:w-64">
            <div className="flex-shrink-0">
              <Link to="/dashboard" >
                <img className="h-8 w-auto" src={masLogo} alt="Workflow" />
              </Link>
            </div>
          </div>

          {/* <!-- Search section --> */}
          {/* <div className="flex-1 flex justify-center lg:justify-end">
            <div className="w-full px-2 lg:px-6">
              <label htmlFor="search" className="sr-only">Search programs</label>
              <div className="relative text-blue-200 focus-within:text-gray-400">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
                  </svg>
                </div>
                <input id="search" name="search" className="block w-full pl-10 pr-3 py-2 border border-transparent rounded-md leading-5 bg-gray-400 bg-opacity-25 text-gray-100 placeholder-gray-200 focus:outline-none focus:bg-white focus:ring-0 focus:placeholder-gray-400 focus:text-gray-900 sm:text-sm" placeholder="Search programs, clubs, athletes" type="search" />
              </div>
            </div>
          </div> */}
          <div className="flex lg:hidden">
            {/* <!-- Mobile menu button --> */}
            <button type="button" className="bg-blue-600 inline-flex items-center justify-center p-2 rounded-md text-blue-400 hover:text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue-600 focus:ring-white" aria-controls="mobile-menu" aria-expanded="false">
              <span className="sr-only">Open main menu</span>
              {/* <!--
                Icon when menu is closed.

                Heroicon name: outline/menu-alt-1

                Menu open: "hidden", Menu closed: "block"
              --> */}
              <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" />
              </svg>
              {/* <!--
                Icon when menu is open.

                Heroicon name: outline/x

                Menu open: "block", Menu closed: "hidden"
              --> */}
              <svg className="hidden h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          {/* <!-- Links section --> */}
          <div className="hidden lg:block lg:w-80">
            <div className="flex items-center justify-end">
              <div className="flex">
                <Link to="/dashboard" >
                  <div className="px-3 py-2 rounded-md text-sm font-medium text-gray-100 hover:text-gray-300">
                    Dashboard
                  </div>
                </Link>
                {/* <Link to="/dashboard" >
                  <div className="px-3 py-2 rounded-md text-sm font-medium text-gray-100 hover:text-gray-300">
                    Programs
                  </div>
                </Link>
                <Link to="/dashboard" >
                  <div className="px-3 py-2 rounded-md text-sm font-medium text-gray-100 hover:text-gray-300">
                    Clubs
                  </div>
                </Link> */}
                <Link to="/login" >
                  <div className="px-3 py-2 rounded-md text-sm font-medium text-gray-100 hover:text-gray-300">
                    Logout
                  </div>
                </Link>
              </div>
              {/* <!-- Profile dropdown --> */}
              
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Mobile menu, show/hide based on menu state. --> */}
      <div className="lg:hidden" id="mobile-menu">
        <div className="px-2 pt-2 pb-3">
          <a href="/" className="block px-3 py-2 rounded-md text-base font-medium text-white bg-blue-800">Dashboard</a>
          <a href="/" className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-blue-200 hover:text-blue-100 hover:bg-blue-600">Support</a>
        </div>
        <div className="pt-4 pb-3 border-t border-blue-800">
          <div className="px-2">
            <a href="/" className="block px-3 py-2 rounded-md text-base font-medium text-blue-200 hover:text-blue-100 hover:bg-blue-600">Your Profile</a>
            <a href="/" className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-blue-200 hover:text-blue-100 hover:bg-blue-600">Settings</a>
            <a href="/" className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-blue-200 hover:text-blue-100 hover:bg-blue-600">Sign out</a>
          </div>
        </div>
      </div>
    </nav>
  )
}


export default Nav;