import { useState } from 'react'
import { Transition } from '@headlessui/react'
import masLogo from '../assets/mas-logo.svg'
import { Link } from 'react-router-dom';


function Terms() {
    return (
        <div>
          <p>TERMS</p>
        </div>
    );
  }
  
  export default Terms;


