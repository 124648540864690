import React from 'react';
import SessionCard from './SessionCard';


const SessionList = () => {
  return (
    <ul className="space-y-4">
      {
        program.sessions.map((session, idx) => {
          return <SessionCard idx={idx} key={idx} session={session} />
        })
      }      
    </ul>
  )
}


const program = {
  "id" : "1",
  "clubId" : "",
  "name" : "Pre Season Conditioning",
  "userId" : "",
  "sessions" : [
    {
      "id" : "conditioning-0",
      "index" : 0,
      "timeTrial" : null,
      "weekId" : 0,
      "clubId" : "",
      "typeId" : 0,
      "editedAt" : 1615265214,
      "challengeTypeId" : 0,
      "programId" : "",
      "rest" : 240,
      "isLastSession" : true,
      "intervals" : [
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "4E34E801-606E-45DD-9DD3-7AE11E1570B0",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 180,
          "percentage" : 0.84999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "5F493A9F-A9CB-4A97-89A7-E329046E5504",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.29999999999999999,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "81965A21-6B75-4FD5-94C9-6B8EAB5015DD",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 180,
          "percentage" : 0.84999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "F23F10F8-D5C9-4C8A-AB56-192A4AF093D6",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.29999999999999999,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "47DE0666-0CEE-4FF5-AC2B-78895C424288",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 180,
          "percentage" : 0.84999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "07A4D5DB-9D30-4990-B519-01EDCE614BAD",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.29999999999999999,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "rest",
          "set" : 0,
          "isComplete" : false,
          "id" : "91FBAE5B-356C-4D02-B602-ADC4E5EB73B9",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 240,
          "percentage" : 0,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "33EFAF8D-6D0F-4E85-9A12-734DD942B608",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 180,
          "percentage" : 0.84999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "9F84BBBA-BFAD-403D-9223-404C9F3BC4FA",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.29999999999999999,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "5211E880-6ED3-45A6-AF7A-2771560028FF",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 180,
          "percentage" : 0.84999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "82A615A4-BC5E-4828-A88F-EAC304046746",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.29999999999999999,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "3DE11207-2E29-4F1A-936F-37032A4B37B4",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 180,
          "percentage" : 0.84999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "A5B452BC-D804-44CB-AA3B-4DC873518032",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.29999999999999999,
          "styleId" : "",
          "index" : 0
        }
      ],
      "sets" : [
        {
          "id" : "0",
          "reps" : [
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "4E34E801-606E-45DD-9DD3-7AE11E1570B0",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 180,
              "percentage" : 0.84999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "5F493A9F-A9CB-4A97-89A7-E329046E5504",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.29999999999999999,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "81965A21-6B75-4FD5-94C9-6B8EAB5015DD",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 180,
              "percentage" : 0.84999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "F23F10F8-D5C9-4C8A-AB56-192A4AF093D6",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.29999999999999999,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "47DE0666-0CEE-4FF5-AC2B-78895C424288",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 180,
              "percentage" : 0.84999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "07A4D5DB-9D30-4990-B519-01EDCE614BAD",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.29999999999999999,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "rest",
              "set" : 0,
              "isComplete" : false,
              "id" : "91FBAE5B-356C-4D02-B602-ADC4E5EB73B9",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 240,
              "percentage" : 0,
              "styleId" : "",
              "index" : 0
            }
          ]
        },
        {
          "id" : "1",
          "reps" : [
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "33EFAF8D-6D0F-4E85-9A12-734DD942B608",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 180,
              "percentage" : 0.84999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "9F84BBBA-BFAD-403D-9223-404C9F3BC4FA",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.29999999999999999,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "5211E880-6ED3-45A6-AF7A-2771560028FF",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 180,
              "percentage" : 0.84999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "82A615A4-BC5E-4828-A88F-EAC304046746",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.29999999999999999,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "3DE11207-2E29-4F1A-936F-37032A4B37B4",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 180,
              "percentage" : 0.84999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "A5B452BC-D804-44CB-AA3B-4DC873518032",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.29999999999999999,
              "styleId" : "",
              "index" : 0
            }
          ]
        }
      ],
      "_type" : "setsAndReps"
    },
    {
      "id" : "conditioning-1",
      "index" : 1,
      "timeTrial" : null,
      "weekId" : 0,
      "clubId" : "",
      "typeId" : 0,
      "editedAt" : 1615265214,
      "challengeTypeId" : 0,
      "programId" : "",
      "rest" : 180,
      "isLastSession" : true,
      "intervals" : [
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "7ABF7145-84E4-41FC-A255-6E57F83A44D2",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "99252E78-FB09-4B1C-B68C-2B091DF09EB0",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.5,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "14A366E9-331D-414E-86A7-2574AEE17E8C",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "081B1BFE-4C16-4C7C-8D4D-8F29BF83B886",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.5,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "CC597DAC-529C-4C07-AAD9-5B756EC5E4D2",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "A9711632-16DC-44D5-8ABB-C6F1ED47E805",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.5,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "E106598A-6758-4336-A19A-EF16A275D007",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "7BDCE8AE-D14E-4451-BD57-0F3ADBC5CC9A",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.5,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "rest",
          "set" : 0,
          "isComplete" : false,
          "id" : "904C8B64-B81F-438A-990C-17B89B4AD638",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 240,
          "percentage" : 0,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "B9D3BECE-086C-4D2B-B2D0-B1808293B116",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "AB7BCA48-B3D4-405A-BEB0-DED74F09E34A",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.5,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "E22CDF38-A4C0-4CFB-86A3-8B3ECFC41F14",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "CB6B24E6-DE2E-40B3-8E8D-B74B0B0EC173",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.5,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "49A2C604-FE3A-434C-9CCB-3918930ACFB8",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "75412BEF-5287-4673-BD96-065793E6AB7A",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.5,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "1AF29B30-0B7C-42C3-AB64-B407E9790890",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "55C662C7-619F-41B1-AB56-1012CD966609",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.5,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "rest",
          "set" : 1,
          "isComplete" : false,
          "id" : "24434818-0573-46A1-BC1F-339AD6FD273F",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 240,
          "percentage" : 0,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 2,
          "isComplete" : false,
          "id" : "E8E80BD5-C329-4C7B-AFD4-1F5AD4486701",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 2,
          "isComplete" : false,
          "id" : "9192EC69-3B2E-45E7-8C39-CB3C3FF8C4A5",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.5,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 2,
          "isComplete" : false,
          "id" : "1A56723E-C002-4320-AD35-66B4BAAAED46",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 2,
          "isComplete" : false,
          "id" : "E7FFD648-AC3E-48DE-8CEF-82D5CFF33680",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.5,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 2,
          "isComplete" : false,
          "id" : "5ACDC356-0FF4-4AE6-98C5-430740F5D8AE",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 2,
          "isComplete" : false,
          "id" : "429920EA-1FCE-4823-AACD-696EBB65D5D5",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.5,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 2,
          "isComplete" : false,
          "id" : "3FA6CB5F-3626-4365-9DDB-3302A68E9A81",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 2,
          "isComplete" : false,
          "id" : "7AC44D86-66C5-45D7-A14A-A4046E6597AA",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.5,
          "styleId" : "",
          "index" : 0
        }
      ],
      "sets" : [
        {
          "id" : "0",
          "reps" : [
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "7ABF7145-84E4-41FC-A255-6E57F83A44D2",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "99252E78-FB09-4B1C-B68C-2B091DF09EB0",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.5,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "14A366E9-331D-414E-86A7-2574AEE17E8C",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "081B1BFE-4C16-4C7C-8D4D-8F29BF83B886",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.5,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "CC597DAC-529C-4C07-AAD9-5B756EC5E4D2",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "A9711632-16DC-44D5-8ABB-C6F1ED47E805",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.5,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "E106598A-6758-4336-A19A-EF16A275D007",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "7BDCE8AE-D14E-4451-BD57-0F3ADBC5CC9A",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.5,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "rest",
              "set" : 0,
              "isComplete" : false,
              "id" : "904C8B64-B81F-438A-990C-17B89B4AD638",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 240,
              "percentage" : 0,
              "styleId" : "",
              "index" : 0
            }
          ]
        },
        {
          "id" : "1",
          "reps" : [
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "B9D3BECE-086C-4D2B-B2D0-B1808293B116",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "AB7BCA48-B3D4-405A-BEB0-DED74F09E34A",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.5,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "E22CDF38-A4C0-4CFB-86A3-8B3ECFC41F14",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "CB6B24E6-DE2E-40B3-8E8D-B74B0B0EC173",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.5,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "49A2C604-FE3A-434C-9CCB-3918930ACFB8",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "75412BEF-5287-4673-BD96-065793E6AB7A",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.5,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "1AF29B30-0B7C-42C3-AB64-B407E9790890",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "55C662C7-619F-41B1-AB56-1012CD966609",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.5,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "rest",
              "set" : 1,
              "isComplete" : false,
              "id" : "24434818-0573-46A1-BC1F-339AD6FD273F",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 240,
              "percentage" : 0,
              "styleId" : "",
              "index" : 0
            }
          ]
        },
        {
          "id" : "2",
          "reps" : [
            {
              "typeId" : "work",
              "set" : 2,
              "isComplete" : false,
              "id" : "E8E80BD5-C329-4C7B-AFD4-1F5AD4486701",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 2,
              "isComplete" : false,
              "id" : "9192EC69-3B2E-45E7-8C39-CB3C3FF8C4A5",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.5,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 2,
              "isComplete" : false,
              "id" : "1A56723E-C002-4320-AD35-66B4BAAAED46",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 2,
              "isComplete" : false,
              "id" : "E7FFD648-AC3E-48DE-8CEF-82D5CFF33680",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.5,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 2,
              "isComplete" : false,
              "id" : "5ACDC356-0FF4-4AE6-98C5-430740F5D8AE",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 2,
              "isComplete" : false,
              "id" : "429920EA-1FCE-4823-AACD-696EBB65D5D5",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.5,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 2,
              "isComplete" : false,
              "id" : "3FA6CB5F-3626-4365-9DDB-3302A68E9A81",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 2,
              "isComplete" : false,
              "id" : "7AC44D86-66C5-45D7-A14A-A4046E6597AA",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.5,
              "styleId" : "",
              "index" : 0
            }
          ]
        }
      ],
      "_type" : "setsAndReps"
    },
    {
      "id" : "conditioning-2",
      "index" : 2,
      "timeTrial" : null,
      "weekId" : 0,
      "clubId" : "",
      "typeId" : 0,
      "editedAt" : 1615265214,
      "challengeTypeId" : 0,
      "programId" : "",
      "rest" : 240,
      "isLastSession" : true,
      "intervals" : [
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "4E34E801-606E-45DD-9DD3-7AE11E1570B0",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 180,
          "percentage" : 0.84999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "5F493A9F-A9CB-4A97-89A7-E329046E5504",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.29999999999999999,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "81965A21-6B75-4FD5-94C9-6B8EAB5015DD",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 180,
          "percentage" : 0.84999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "F23F10F8-D5C9-4C8A-AB56-192A4AF093D6",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.29999999999999999,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "47DE0666-0CEE-4FF5-AC2B-78895C424288",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 180,
          "percentage" : 0.84999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "07A4D5DB-9D30-4990-B519-01EDCE614BAD",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.29999999999999999,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "rest",
          "set" : 0,
          "isComplete" : false,
          "id" : "91FBAE5B-356C-4D02-B602-ADC4E5EB73B9",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 240,
          "percentage" : 0,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "33EFAF8D-6D0F-4E85-9A12-734DD942B608",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 180,
          "percentage" : 0.84999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "9F84BBBA-BFAD-403D-9223-404C9F3BC4FA",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.29999999999999999,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "5211E880-6ED3-45A6-AF7A-2771560028FF",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 180,
          "percentage" : 0.84999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "82A615A4-BC5E-4828-A88F-EAC304046746",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.29999999999999999,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "3DE11207-2E29-4F1A-936F-37032A4B37B4",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 180,
          "percentage" : 0.84999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "A5B452BC-D804-44CB-AA3B-4DC873518032",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.29999999999999999,
          "styleId" : "",
          "index" : 0
        }
      ],
      "sets" : [
        {
          "id" : "0",
          "reps" : [
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "4E34E801-606E-45DD-9DD3-7AE11E1570B0",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 180,
              "percentage" : 0.84999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "5F493A9F-A9CB-4A97-89A7-E329046E5504",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.29999999999999999,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "81965A21-6B75-4FD5-94C9-6B8EAB5015DD",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 180,
              "percentage" : 0.84999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "F23F10F8-D5C9-4C8A-AB56-192A4AF093D6",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.29999999999999999,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "47DE0666-0CEE-4FF5-AC2B-78895C424288",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 180,
              "percentage" : 0.84999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "07A4D5DB-9D30-4990-B519-01EDCE614BAD",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.29999999999999999,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "rest",
              "set" : 0,
              "isComplete" : false,
              "id" : "91FBAE5B-356C-4D02-B602-ADC4E5EB73B9",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 240,
              "percentage" : 0,
              "styleId" : "",
              "index" : 0
            }
          ]
        },
        {
          "id" : "1",
          "reps" : [
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "33EFAF8D-6D0F-4E85-9A12-734DD942B608",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 180,
              "percentage" : 0.84999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "9F84BBBA-BFAD-403D-9223-404C9F3BC4FA",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.29999999999999999,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "5211E880-6ED3-45A6-AF7A-2771560028FF",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 180,
              "percentage" : 0.84999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "82A615A4-BC5E-4828-A88F-EAC304046746",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.29999999999999999,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "3DE11207-2E29-4F1A-936F-37032A4B37B4",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 180,
              "percentage" : 0.84999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "A5B452BC-D804-44CB-AA3B-4DC873518032",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.29999999999999999,
              "styleId" : "",
              "index" : 0
            }
          ]
        }
      ],
      "_type" : "setsAndReps"
    },
    {
      "id" : "conditioning-3",
      "index" : 3,
      "timeTrial" : null,
      "weekId" : 0,
      "clubId" : "",
      "typeId" : 0,
      "editedAt" : 1615265214,
      "challengeTypeId" : 0,
      "programId" : "",
      "rest" : 240,
      "isLastSession" : true,
      "intervals" : [
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "E0F26385-956E-4733-BD77-0E11CF801415",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "D1B4897F-B1AA-4446-BA13-4771B58C71F3",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.5,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "AAF99D93-ACDD-49B9-A219-802E81C3A7B8",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "2392338E-83D2-4DE3-999B-19210CA807EA",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.5,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "2BC9300D-65CE-4F38-A65C-0A3D543D3554",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "728A6F4E-49BF-4CD3-91C1-F7F4CB42E0C0",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.5,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "CBC04723-2FF8-4B4A-B5D9-385E61201DA4",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "EE3D0087-0EEA-4B64-AF78-E78B96B7AEA1",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.5,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "E15DDF76-1623-431E-9B08-92905B9A8446",
          "rep" : 4,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "1D5599DF-A714-4B5B-9C02-C52C65405DB9",
          "rep" : 4,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.5,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "21F3EA97-F717-41A3-9803-B3D078D4266E",
          "rep" : 5,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "1F7F5F01-F9B7-43B0-A1EF-FFE54DC0530B",
          "rep" : 5,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.5,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "rest",
          "set" : 0,
          "isComplete" : false,
          "id" : "0C2215CB-A307-4196-A366-5325226994A9",
          "rep" : 5,
          "recordedDistance" : 0,
          "seconds" : 240,
          "percentage" : 0,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "94E73DAA-FFCA-40CF-BD00-AAA49CEA5F99",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "AA06A331-EE5C-4D8B-868C-D08380DA0777",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.5,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "C2CD0063-0E73-4409-ACC7-0ED5E866D778",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "5C7C195D-43CA-4DB7-BDAA-19AE7A9D2EE6",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.5,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "061EE9A2-2EF8-4BF4-B552-F8F8F2902F2B",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "3BAC0713-6254-4F8D-88BB-E5F24AE506C9",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.5,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "E0808412-54F1-4324-BC46-D4261CE61B23",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "E1423680-C4F4-487D-8D40-AE0788E9D4A3",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.5,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "2743D829-60C4-4605-B86E-A21A59F5030C",
          "rep" : 4,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "36F44BF4-F622-4E37-8AF2-D6E97AB6A81B",
          "rep" : 4,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.5,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "8760F9F5-C0A7-4A5B-950B-491C1C406562",
          "rep" : 5,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "77984FB1-C0A0-48E0-A61C-41390117AA98",
          "rep" : 5,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.5,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "rest",
          "set" : 1,
          "isComplete" : false,
          "id" : "A4A3C899-2D4C-4EF0-8679-C4A86D37F3CB",
          "rep" : 5,
          "recordedDistance" : 0,
          "seconds" : 240,
          "percentage" : 0,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 2,
          "isComplete" : false,
          "id" : "36E20B7D-DC15-4821-94AE-84EAC409823E",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 2,
          "isComplete" : false,
          "id" : "AC69DECF-9A1D-4F19-9418-A32152B04BD2",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.5,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 2,
          "isComplete" : false,
          "id" : "DDCB2609-263A-402B-8C45-29C2D083DA70",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 2,
          "isComplete" : false,
          "id" : "C9CEC5E7-851B-4D81-BAD3-9F79454F31D0",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.5,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 2,
          "isComplete" : false,
          "id" : "742A2C7E-5432-4EA8-8184-679BA6675F1C",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 2,
          "isComplete" : false,
          "id" : "E5EC8492-D66F-4574-AC47-8E52E1983F94",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.5,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 2,
          "isComplete" : false,
          "id" : "B1EE8593-FEEF-4716-A64E-E755912368FC",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 2,
          "isComplete" : false,
          "id" : "98382219-986E-4A8B-87E6-C2F0DD102DFF",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.5,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 2,
          "isComplete" : false,
          "id" : "ACEBDA88-0EE4-41EB-971E-B0C115BF699A",
          "rep" : 4,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 2,
          "isComplete" : false,
          "id" : "2C405F07-B731-44A0-8FFF-9269C19CDF0C",
          "rep" : 4,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.5,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 2,
          "isComplete" : false,
          "id" : "A220E004-01A3-49E8-93F1-F13EC92DF7DE",
          "rep" : 5,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 2,
          "isComplete" : false,
          "id" : "6321424D-FF9B-4228-8006-B8FA0A8C6188",
          "rep" : 5,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.5,
          "styleId" : "",
          "index" : 0
        }
      ],
      "sets" : [
        {
          "id" : "0",
          "reps" : [
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "E0F26385-956E-4733-BD77-0E11CF801415",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "D1B4897F-B1AA-4446-BA13-4771B58C71F3",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.5,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "AAF99D93-ACDD-49B9-A219-802E81C3A7B8",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "2392338E-83D2-4DE3-999B-19210CA807EA",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.5,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "2BC9300D-65CE-4F38-A65C-0A3D543D3554",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "728A6F4E-49BF-4CD3-91C1-F7F4CB42E0C0",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.5,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "CBC04723-2FF8-4B4A-B5D9-385E61201DA4",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "EE3D0087-0EEA-4B64-AF78-E78B96B7AEA1",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.5,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "E15DDF76-1623-431E-9B08-92905B9A8446",
              "rep" : 4,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "1D5599DF-A714-4B5B-9C02-C52C65405DB9",
              "rep" : 4,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.5,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "21F3EA97-F717-41A3-9803-B3D078D4266E",
              "rep" : 5,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "1F7F5F01-F9B7-43B0-A1EF-FFE54DC0530B",
              "rep" : 5,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.5,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "rest",
              "set" : 0,
              "isComplete" : false,
              "id" : "0C2215CB-A307-4196-A366-5325226994A9",
              "rep" : 5,
              "recordedDistance" : 0,
              "seconds" : 240,
              "percentage" : 0,
              "styleId" : "",
              "index" : 0
            }
          ]
        },
        {
          "id" : "1",
          "reps" : [
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "94E73DAA-FFCA-40CF-BD00-AAA49CEA5F99",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "AA06A331-EE5C-4D8B-868C-D08380DA0777",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.5,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "C2CD0063-0E73-4409-ACC7-0ED5E866D778",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "5C7C195D-43CA-4DB7-BDAA-19AE7A9D2EE6",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.5,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "061EE9A2-2EF8-4BF4-B552-F8F8F2902F2B",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "3BAC0713-6254-4F8D-88BB-E5F24AE506C9",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.5,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "E0808412-54F1-4324-BC46-D4261CE61B23",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "E1423680-C4F4-487D-8D40-AE0788E9D4A3",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.5,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "2743D829-60C4-4605-B86E-A21A59F5030C",
              "rep" : 4,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "36F44BF4-F622-4E37-8AF2-D6E97AB6A81B",
              "rep" : 4,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.5,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "8760F9F5-C0A7-4A5B-950B-491C1C406562",
              "rep" : 5,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "77984FB1-C0A0-48E0-A61C-41390117AA98",
              "rep" : 5,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.5,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "rest",
              "set" : 1,
              "isComplete" : false,
              "id" : "A4A3C899-2D4C-4EF0-8679-C4A86D37F3CB",
              "rep" : 5,
              "recordedDistance" : 0,
              "seconds" : 240,
              "percentage" : 0,
              "styleId" : "",
              "index" : 0
            }
          ]
        },
        {
          "id" : "2",
          "reps" : [
            {
              "typeId" : "work",
              "set" : 2,
              "isComplete" : false,
              "id" : "36E20B7D-DC15-4821-94AE-84EAC409823E",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 2,
              "isComplete" : false,
              "id" : "AC69DECF-9A1D-4F19-9418-A32152B04BD2",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.5,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 2,
              "isComplete" : false,
              "id" : "DDCB2609-263A-402B-8C45-29C2D083DA70",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 2,
              "isComplete" : false,
              "id" : "C9CEC5E7-851B-4D81-BAD3-9F79454F31D0",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.5,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 2,
              "isComplete" : false,
              "id" : "742A2C7E-5432-4EA8-8184-679BA6675F1C",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 2,
              "isComplete" : false,
              "id" : "E5EC8492-D66F-4574-AC47-8E52E1983F94",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.5,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 2,
              "isComplete" : false,
              "id" : "B1EE8593-FEEF-4716-A64E-E755912368FC",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 2,
              "isComplete" : false,
              "id" : "98382219-986E-4A8B-87E6-C2F0DD102DFF",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.5,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 2,
              "isComplete" : false,
              "id" : "ACEBDA88-0EE4-41EB-971E-B0C115BF699A",
              "rep" : 4,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 2,
              "isComplete" : false,
              "id" : "2C405F07-B731-44A0-8FFF-9269C19CDF0C",
              "rep" : 4,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.5,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 2,
              "isComplete" : false,
              "id" : "A220E004-01A3-49E8-93F1-F13EC92DF7DE",
              "rep" : 5,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 2,
              "isComplete" : false,
              "id" : "6321424D-FF9B-4228-8006-B8FA0A8C6188",
              "rep" : 5,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.5,
              "styleId" : "",
              "index" : 0
            }
          ]
        }
      ],
      "_type" : "setsAndReps"
    },
    {
      "id" : "conditioning-4",
      "index" : 4,
      "timeTrial" : null,
      "weekId" : 0,
      "clubId" : "",
      "typeId" : 0,
      "editedAt" : 1615265214,
      "challengeTypeId" : 0,
      "programId" : "",
      "rest" : 240,
      "isLastSession" : true,
      "intervals" : [
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "12E7118A-D603-4C77-B76D-EE1E7A324710",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 180,
          "percentage" : 0.84999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "7B5ED1FE-202A-458E-B1BB-DFC48C535429",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.29999999999999999,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "06787C04-20FF-4F6C-AF68-0A6BA1231E7F",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 180,
          "percentage" : 0.84999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "3814B935-B22C-48A9-9174-A574E0846F75",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.29999999999999999,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "68CDF743-CE71-428C-AFAF-BF6AF6548232",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 180,
          "percentage" : 0.84999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "BCB605ED-7106-4CFE-A6D5-7BB583AB557E",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.29999999999999999,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "C65A54ED-1C6D-4F95-B4BC-DA830E487FEB",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 180,
          "percentage" : 0.84999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "2A1D2B7B-804E-4B16-A5D0-41C0ED862A91",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.29999999999999999,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "rest",
          "set" : 0,
          "isComplete" : false,
          "id" : "D16845C3-54E0-4825-82D2-66DA88F608FE",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 240,
          "percentage" : 0,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "43C1E8A8-F461-4DB8-A7C3-993E7E9F87A7",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 180,
          "percentage" : 0.84999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "CE7B596D-C94A-4582-AD80-B2FE716D6794",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.29999999999999999,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "3A507BE8-1CC3-4637-BB3F-D0728CBC8737",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 180,
          "percentage" : 0.84999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "CA1ECA28-3A06-443A-9B3A-09D5CF29E79A",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.29999999999999999,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "C5D2EC4C-C4AA-4A55-9DBB-F67323242E9C",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 180,
          "percentage" : 0.84999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "12B7894E-3DB0-4B27-B58A-1E527B43BDE2",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.29999999999999999,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "DBF47067-25B9-4929-9B66-0C4722C15AB5",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 180,
          "percentage" : 0.84999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "A2D70A8F-235A-49F1-B137-85244BDE773E",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.29999999999999999,
          "styleId" : "",
          "index" : 0
        }
      ],
      "sets" : [
        {
          "id" : "0",
          "reps" : [
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "12E7118A-D603-4C77-B76D-EE1E7A324710",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 180,
              "percentage" : 0.84999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "7B5ED1FE-202A-458E-B1BB-DFC48C535429",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.29999999999999999,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "06787C04-20FF-4F6C-AF68-0A6BA1231E7F",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 180,
              "percentage" : 0.84999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "3814B935-B22C-48A9-9174-A574E0846F75",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.29999999999999999,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "68CDF743-CE71-428C-AFAF-BF6AF6548232",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 180,
              "percentage" : 0.84999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "BCB605ED-7106-4CFE-A6D5-7BB583AB557E",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.29999999999999999,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "C65A54ED-1C6D-4F95-B4BC-DA830E487FEB",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 180,
              "percentage" : 0.84999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "2A1D2B7B-804E-4B16-A5D0-41C0ED862A91",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.29999999999999999,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "rest",
              "set" : 0,
              "isComplete" : false,
              "id" : "D16845C3-54E0-4825-82D2-66DA88F608FE",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 240,
              "percentage" : 0,
              "styleId" : "",
              "index" : 0
            }
          ]
        },
        {
          "id" : "1",
          "reps" : [
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "43C1E8A8-F461-4DB8-A7C3-993E7E9F87A7",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 180,
              "percentage" : 0.84999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "CE7B596D-C94A-4582-AD80-B2FE716D6794",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.29999999999999999,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "3A507BE8-1CC3-4637-BB3F-D0728CBC8737",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 180,
              "percentage" : 0.84999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "CA1ECA28-3A06-443A-9B3A-09D5CF29E79A",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.29999999999999999,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "C5D2EC4C-C4AA-4A55-9DBB-F67323242E9C",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 180,
              "percentage" : 0.84999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "12B7894E-3DB0-4B27-B58A-1E527B43BDE2",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.29999999999999999,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "DBF47067-25B9-4929-9B66-0C4722C15AB5",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 180,
              "percentage" : 0.84999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "A2D70A8F-235A-49F1-B137-85244BDE773E",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.29999999999999999,
              "styleId" : "",
              "index" : 0
            }
          ]
        }
      ],
      "_type" : "setsAndReps"
    },
    {
      "id" : "conditioning-5",
      "index" : 5,
      "timeTrial" : null,
      "weekId" : 0,
      "clubId" : "",
      "typeId" : 0,
      "editedAt" : 1615265214,
      "challengeTypeId" : 0,
      "programId" : "",
      "rest" : 240,
      "isLastSession" : true,
      "intervals" : [
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "E0F26385-956E-4733-BD77-0E11CF801415",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "D1B4897F-B1AA-4446-BA13-4771B58C71F3",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.5,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "AAF99D93-ACDD-49B9-A219-802E81C3A7B8",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "2392338E-83D2-4DE3-999B-19210CA807EA",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.5,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "2BC9300D-65CE-4F38-A65C-0A3D543D3554",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "728A6F4E-49BF-4CD3-91C1-F7F4CB42E0C0",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.5,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "CBC04723-2FF8-4B4A-B5D9-385E61201DA4",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "EE3D0087-0EEA-4B64-AF78-E78B96B7AEA1",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.5,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "E15DDF76-1623-431E-9B08-92905B9A8446",
          "rep" : 4,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "1D5599DF-A714-4B5B-9C02-C52C65405DB9",
          "rep" : 4,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.5,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "21F3EA97-F717-41A3-9803-B3D078D4266E",
          "rep" : 5,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "1F7F5F01-F9B7-43B0-A1EF-FFE54DC0530B",
          "rep" : 5,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.5,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "rest",
          "set" : 0,
          "isComplete" : false,
          "id" : "0C2215CB-A307-4196-A366-5325226994A9",
          "rep" : 5,
          "recordedDistance" : 0,
          "seconds" : 240,
          "percentage" : 0,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "94E73DAA-FFCA-40CF-BD00-AAA49CEA5F99",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "AA06A331-EE5C-4D8B-868C-D08380DA0777",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.5,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "C2CD0063-0E73-4409-ACC7-0ED5E866D778",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "5C7C195D-43CA-4DB7-BDAA-19AE7A9D2EE6",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.5,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "061EE9A2-2EF8-4BF4-B552-F8F8F2902F2B",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "3BAC0713-6254-4F8D-88BB-E5F24AE506C9",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.5,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "E0808412-54F1-4324-BC46-D4261CE61B23",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "E1423680-C4F4-487D-8D40-AE0788E9D4A3",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.5,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "2743D829-60C4-4605-B86E-A21A59F5030C",
          "rep" : 4,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "36F44BF4-F622-4E37-8AF2-D6E97AB6A81B",
          "rep" : 4,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.5,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "8760F9F5-C0A7-4A5B-950B-491C1C406562",
          "rep" : 5,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "77984FB1-C0A0-48E0-A61C-41390117AA98",
          "rep" : 5,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.5,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "rest",
          "set" : 1,
          "isComplete" : false,
          "id" : "A4A3C899-2D4C-4EF0-8679-C4A86D37F3CB",
          "rep" : 5,
          "recordedDistance" : 0,
          "seconds" : 240,
          "percentage" : 0,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 2,
          "isComplete" : false,
          "id" : "36E20B7D-DC15-4821-94AE-84EAC409823E",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 2,
          "isComplete" : false,
          "id" : "AC69DECF-9A1D-4F19-9418-A32152B04BD2",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.5,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 2,
          "isComplete" : false,
          "id" : "DDCB2609-263A-402B-8C45-29C2D083DA70",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 2,
          "isComplete" : false,
          "id" : "C9CEC5E7-851B-4D81-BAD3-9F79454F31D0",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.5,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 2,
          "isComplete" : false,
          "id" : "742A2C7E-5432-4EA8-8184-679BA6675F1C",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 2,
          "isComplete" : false,
          "id" : "E5EC8492-D66F-4574-AC47-8E52E1983F94",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.5,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 2,
          "isComplete" : false,
          "id" : "B1EE8593-FEEF-4716-A64E-E755912368FC",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 2,
          "isComplete" : false,
          "id" : "98382219-986E-4A8B-87E6-C2F0DD102DFF",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.5,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 2,
          "isComplete" : false,
          "id" : "ACEBDA88-0EE4-41EB-971E-B0C115BF699A",
          "rep" : 4,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 2,
          "isComplete" : false,
          "id" : "2C405F07-B731-44A0-8FFF-9269C19CDF0C",
          "rep" : 4,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.5,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 2,
          "isComplete" : false,
          "id" : "A220E004-01A3-49E8-93F1-F13EC92DF7DE",
          "rep" : 5,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 2,
          "isComplete" : false,
          "id" : "6321424D-FF9B-4228-8006-B8FA0A8C6188",
          "rep" : 5,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.5,
          "styleId" : "",
          "index" : 0
        }
      ],
      "sets" : [
        {
          "id" : "0",
          "reps" : [
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "E0F26385-956E-4733-BD77-0E11CF801415",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "D1B4897F-B1AA-4446-BA13-4771B58C71F3",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.5,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "AAF99D93-ACDD-49B9-A219-802E81C3A7B8",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "2392338E-83D2-4DE3-999B-19210CA807EA",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.5,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "2BC9300D-65CE-4F38-A65C-0A3D543D3554",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "728A6F4E-49BF-4CD3-91C1-F7F4CB42E0C0",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.5,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "CBC04723-2FF8-4B4A-B5D9-385E61201DA4",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "EE3D0087-0EEA-4B64-AF78-E78B96B7AEA1",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.5,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "E15DDF76-1623-431E-9B08-92905B9A8446",
              "rep" : 4,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "1D5599DF-A714-4B5B-9C02-C52C65405DB9",
              "rep" : 4,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.5,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "21F3EA97-F717-41A3-9803-B3D078D4266E",
              "rep" : 5,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "1F7F5F01-F9B7-43B0-A1EF-FFE54DC0530B",
              "rep" : 5,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.5,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "rest",
              "set" : 0,
              "isComplete" : false,
              "id" : "0C2215CB-A307-4196-A366-5325226994A9",
              "rep" : 5,
              "recordedDistance" : 0,
              "seconds" : 240,
              "percentage" : 0,
              "styleId" : "",
              "index" : 0
            }
          ]
        },
        {
          "id" : "1",
          "reps" : [
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "94E73DAA-FFCA-40CF-BD00-AAA49CEA5F99",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "AA06A331-EE5C-4D8B-868C-D08380DA0777",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.5,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "C2CD0063-0E73-4409-ACC7-0ED5E866D778",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "5C7C195D-43CA-4DB7-BDAA-19AE7A9D2EE6",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.5,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "061EE9A2-2EF8-4BF4-B552-F8F8F2902F2B",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "3BAC0713-6254-4F8D-88BB-E5F24AE506C9",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.5,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "E0808412-54F1-4324-BC46-D4261CE61B23",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "E1423680-C4F4-487D-8D40-AE0788E9D4A3",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.5,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "2743D829-60C4-4605-B86E-A21A59F5030C",
              "rep" : 4,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "36F44BF4-F622-4E37-8AF2-D6E97AB6A81B",
              "rep" : 4,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.5,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "8760F9F5-C0A7-4A5B-950B-491C1C406562",
              "rep" : 5,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "77984FB1-C0A0-48E0-A61C-41390117AA98",
              "rep" : 5,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.5,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "rest",
              "set" : 1,
              "isComplete" : false,
              "id" : "A4A3C899-2D4C-4EF0-8679-C4A86D37F3CB",
              "rep" : 5,
              "recordedDistance" : 0,
              "seconds" : 240,
              "percentage" : 0,
              "styleId" : "",
              "index" : 0
            }
          ]
        },
        {
          "id" : "2",
          "reps" : [
            {
              "typeId" : "work",
              "set" : 2,
              "isComplete" : false,
              "id" : "36E20B7D-DC15-4821-94AE-84EAC409823E",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 2,
              "isComplete" : false,
              "id" : "AC69DECF-9A1D-4F19-9418-A32152B04BD2",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.5,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 2,
              "isComplete" : false,
              "id" : "DDCB2609-263A-402B-8C45-29C2D083DA70",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 2,
              "isComplete" : false,
              "id" : "C9CEC5E7-851B-4D81-BAD3-9F79454F31D0",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.5,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 2,
              "isComplete" : false,
              "id" : "742A2C7E-5432-4EA8-8184-679BA6675F1C",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 2,
              "isComplete" : false,
              "id" : "E5EC8492-D66F-4574-AC47-8E52E1983F94",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.5,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 2,
              "isComplete" : false,
              "id" : "B1EE8593-FEEF-4716-A64E-E755912368FC",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 2,
              "isComplete" : false,
              "id" : "98382219-986E-4A8B-87E6-C2F0DD102DFF",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.5,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 2,
              "isComplete" : false,
              "id" : "ACEBDA88-0EE4-41EB-971E-B0C115BF699A",
              "rep" : 4,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 2,
              "isComplete" : false,
              "id" : "2C405F07-B731-44A0-8FFF-9269C19CDF0C",
              "rep" : 4,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.5,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 2,
              "isComplete" : false,
              "id" : "A220E004-01A3-49E8-93F1-F13EC92DF7DE",
              "rep" : 5,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 2,
              "isComplete" : false,
              "id" : "6321424D-FF9B-4228-8006-B8FA0A8C6188",
              "rep" : 5,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.5,
              "styleId" : "",
              "index" : 0
            }
          ]
        }
      ],
      "_type" : "setsAndReps"
    },
    {
      "id" : "conditioning-6",
      "index" : 6,
      "timeTrial" : null,
      "weekId" : 0,
      "clubId" : "",
      "typeId" : 0,
      "editedAt" : 1615265214,
      "challengeTypeId" : 0,
      "programId" : "",
      "rest" : 240,
      "isLastSession" : true,
      "intervals" : [
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "E1997324-2F52-4C39-9845-00E67680CC19",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 180,
          "percentage" : 0.84999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "22F3FEF1-71A0-42B6-A879-0797136DA3CC",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.29999999999999999,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "2982B24A-A13B-4B29-85BC-8127DEBCA993",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 180,
          "percentage" : 0.84999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "9E27C634-C3A5-4CE5-97A6-D440065F9CF2",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.29999999999999999,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "869EF3F4-F8A5-4CAD-A89A-813E9A79547F",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 180,
          "percentage" : 0.84999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "D6752112-F3BB-4517-83FF-34813E5FB145",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.29999999999999999,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "26D13DC6-DE49-4380-A2DE-EF40A974F3F9",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 180,
          "percentage" : 0.84999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "40DBBBEB-4437-4BDF-9659-9C6F8C6FB6BA",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.29999999999999999,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "rest",
          "set" : 0,
          "isComplete" : false,
          "id" : "C4A8E663-E85A-47B7-81DD-76713BA01BCE",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 240,
          "percentage" : 0,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "419BE8B2-F07B-46B1-85E6-CDAF765E3B54",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 180,
          "percentage" : 0.84999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "4449728F-D7EB-4BA8-B9E8-B169A6530FCA",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.29999999999999999,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "6082C2AB-B47C-4D31-8315-91CFB1295ECF",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 180,
          "percentage" : 0.84999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "543AA447-979C-4864-A6C2-244F91FD8F8B",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.29999999999999999,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "846667A7-00D7-45CD-B318-9E66B3FAF921",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 180,
          "percentage" : 0.84999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "A7B864B8-C948-4F04-8867-F2AEDC66A5DE",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.29999999999999999,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "993203B3-5E54-410B-A605-E641BDA6ECE1",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 180,
          "percentage" : 0.84999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "77DED604-9B64-408F-8330-20A86CE56803",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.29999999999999999,
          "styleId" : "",
          "index" : 0
        }
      ],
      "sets" : [
        {
          "id" : "0",
          "reps" : [
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "E1997324-2F52-4C39-9845-00E67680CC19",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 180,
              "percentage" : 0.84999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "22F3FEF1-71A0-42B6-A879-0797136DA3CC",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.29999999999999999,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "2982B24A-A13B-4B29-85BC-8127DEBCA993",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 180,
              "percentage" : 0.84999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "9E27C634-C3A5-4CE5-97A6-D440065F9CF2",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.29999999999999999,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "869EF3F4-F8A5-4CAD-A89A-813E9A79547F",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 180,
              "percentage" : 0.84999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "D6752112-F3BB-4517-83FF-34813E5FB145",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.29999999999999999,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "26D13DC6-DE49-4380-A2DE-EF40A974F3F9",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 180,
              "percentage" : 0.84999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "40DBBBEB-4437-4BDF-9659-9C6F8C6FB6BA",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.29999999999999999,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "rest",
              "set" : 0,
              "isComplete" : false,
              "id" : "C4A8E663-E85A-47B7-81DD-76713BA01BCE",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 240,
              "percentage" : 0,
              "styleId" : "",
              "index" : 0
            }
          ]
        },
        {
          "id" : "1",
          "reps" : [
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "419BE8B2-F07B-46B1-85E6-CDAF765E3B54",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 180,
              "percentage" : 0.84999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "4449728F-D7EB-4BA8-B9E8-B169A6530FCA",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.29999999999999999,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "6082C2AB-B47C-4D31-8315-91CFB1295ECF",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 180,
              "percentage" : 0.84999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "543AA447-979C-4864-A6C2-244F91FD8F8B",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.29999999999999999,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "846667A7-00D7-45CD-B318-9E66B3FAF921",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 180,
              "percentage" : 0.84999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "A7B864B8-C948-4F04-8867-F2AEDC66A5DE",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.29999999999999999,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "993203B3-5E54-410B-A605-E641BDA6ECE1",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 180,
              "percentage" : 0.84999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "77DED604-9B64-408F-8330-20A86CE56803",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.29999999999999999,
              "styleId" : "",
              "index" : 0
            }
          ]
        }
      ],
      "_type" : "setsAndReps"
    },
    {
      "id" : "conditioning-7",
      "index" : 7,
      "timeTrial" : null,
      "weekId" : 0,
      "clubId" : "",
      "typeId" : 0,
      "editedAt" : 1615265214,
      "challengeTypeId" : 0,
      "programId" : "",
      "rest" : 240,
      "isLastSession" : true,
      "intervals" : [
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "E0F26385-956E-4733-BD77-0E11CF801415",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "D1B4897F-B1AA-4446-BA13-4771B58C71F3",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.5,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "AAF99D93-ACDD-49B9-A219-802E81C3A7B8",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "2392338E-83D2-4DE3-999B-19210CA807EA",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.5,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "2BC9300D-65CE-4F38-A65C-0A3D543D3554",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "728A6F4E-49BF-4CD3-91C1-F7F4CB42E0C0",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.5,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "CBC04723-2FF8-4B4A-B5D9-385E61201DA4",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "EE3D0087-0EEA-4B64-AF78-E78B96B7AEA1",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.5,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "E15DDF76-1623-431E-9B08-92905B9A8446",
          "rep" : 4,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "1D5599DF-A714-4B5B-9C02-C52C65405DB9",
          "rep" : 4,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.5,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "21F3EA97-F717-41A3-9803-B3D078D4266E",
          "rep" : 5,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "1F7F5F01-F9B7-43B0-A1EF-FFE54DC0530B",
          "rep" : 5,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.5,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "rest",
          "set" : 0,
          "isComplete" : false,
          "id" : "0C2215CB-A307-4196-A366-5325226994A9",
          "rep" : 5,
          "recordedDistance" : 0,
          "seconds" : 240,
          "percentage" : 0,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "94E73DAA-FFCA-40CF-BD00-AAA49CEA5F99",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "AA06A331-EE5C-4D8B-868C-D08380DA0777",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.5,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "C2CD0063-0E73-4409-ACC7-0ED5E866D778",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "5C7C195D-43CA-4DB7-BDAA-19AE7A9D2EE6",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.5,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "061EE9A2-2EF8-4BF4-B552-F8F8F2902F2B",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "3BAC0713-6254-4F8D-88BB-E5F24AE506C9",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.5,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "E0808412-54F1-4324-BC46-D4261CE61B23",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "E1423680-C4F4-487D-8D40-AE0788E9D4A3",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.5,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "2743D829-60C4-4605-B86E-A21A59F5030C",
          "rep" : 4,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "36F44BF4-F622-4E37-8AF2-D6E97AB6A81B",
          "rep" : 4,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.5,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "8760F9F5-C0A7-4A5B-950B-491C1C406562",
          "rep" : 5,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "77984FB1-C0A0-48E0-A61C-41390117AA98",
          "rep" : 5,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.5,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "rest",
          "set" : 1,
          "isComplete" : false,
          "id" : "A4A3C899-2D4C-4EF0-8679-C4A86D37F3CB",
          "rep" : 5,
          "recordedDistance" : 0,
          "seconds" : 240,
          "percentage" : 0,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 2,
          "isComplete" : false,
          "id" : "36E20B7D-DC15-4821-94AE-84EAC409823E",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 2,
          "isComplete" : false,
          "id" : "AC69DECF-9A1D-4F19-9418-A32152B04BD2",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.5,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 2,
          "isComplete" : false,
          "id" : "DDCB2609-263A-402B-8C45-29C2D083DA70",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 2,
          "isComplete" : false,
          "id" : "C9CEC5E7-851B-4D81-BAD3-9F79454F31D0",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.5,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 2,
          "isComplete" : false,
          "id" : "742A2C7E-5432-4EA8-8184-679BA6675F1C",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 2,
          "isComplete" : false,
          "id" : "E5EC8492-D66F-4574-AC47-8E52E1983F94",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.5,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 2,
          "isComplete" : false,
          "id" : "B1EE8593-FEEF-4716-A64E-E755912368FC",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 2,
          "isComplete" : false,
          "id" : "98382219-986E-4A8B-87E6-C2F0DD102DFF",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.5,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 2,
          "isComplete" : false,
          "id" : "ACEBDA88-0EE4-41EB-971E-B0C115BF699A",
          "rep" : 4,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 2,
          "isComplete" : false,
          "id" : "2C405F07-B731-44A0-8FFF-9269C19CDF0C",
          "rep" : 4,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.5,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 2,
          "isComplete" : false,
          "id" : "A220E004-01A3-49E8-93F1-F13EC92DF7DE",
          "rep" : 5,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 2,
          "isComplete" : false,
          "id" : "6321424D-FF9B-4228-8006-B8FA0A8C6188",
          "rep" : 5,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.5,
          "styleId" : "",
          "index" : 0
        }
      ],
      "sets" : [
        {
          "id" : "0",
          "reps" : [
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "E0F26385-956E-4733-BD77-0E11CF801415",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "D1B4897F-B1AA-4446-BA13-4771B58C71F3",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.5,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "AAF99D93-ACDD-49B9-A219-802E81C3A7B8",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "2392338E-83D2-4DE3-999B-19210CA807EA",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.5,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "2BC9300D-65CE-4F38-A65C-0A3D543D3554",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "728A6F4E-49BF-4CD3-91C1-F7F4CB42E0C0",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.5,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "CBC04723-2FF8-4B4A-B5D9-385E61201DA4",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "EE3D0087-0EEA-4B64-AF78-E78B96B7AEA1",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.5,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "E15DDF76-1623-431E-9B08-92905B9A8446",
              "rep" : 4,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "1D5599DF-A714-4B5B-9C02-C52C65405DB9",
              "rep" : 4,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.5,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "21F3EA97-F717-41A3-9803-B3D078D4266E",
              "rep" : 5,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "1F7F5F01-F9B7-43B0-A1EF-FFE54DC0530B",
              "rep" : 5,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.5,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "rest",
              "set" : 0,
              "isComplete" : false,
              "id" : "0C2215CB-A307-4196-A366-5325226994A9",
              "rep" : 5,
              "recordedDistance" : 0,
              "seconds" : 240,
              "percentage" : 0,
              "styleId" : "",
              "index" : 0
            }
          ]
        },
        {
          "id" : "1",
          "reps" : [
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "94E73DAA-FFCA-40CF-BD00-AAA49CEA5F99",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "AA06A331-EE5C-4D8B-868C-D08380DA0777",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.5,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "C2CD0063-0E73-4409-ACC7-0ED5E866D778",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "5C7C195D-43CA-4DB7-BDAA-19AE7A9D2EE6",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.5,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "061EE9A2-2EF8-4BF4-B552-F8F8F2902F2B",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "3BAC0713-6254-4F8D-88BB-E5F24AE506C9",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.5,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "E0808412-54F1-4324-BC46-D4261CE61B23",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "E1423680-C4F4-487D-8D40-AE0788E9D4A3",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.5,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "2743D829-60C4-4605-B86E-A21A59F5030C",
              "rep" : 4,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "36F44BF4-F622-4E37-8AF2-D6E97AB6A81B",
              "rep" : 4,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.5,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "8760F9F5-C0A7-4A5B-950B-491C1C406562",
              "rep" : 5,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "77984FB1-C0A0-48E0-A61C-41390117AA98",
              "rep" : 5,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.5,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "rest",
              "set" : 1,
              "isComplete" : false,
              "id" : "A4A3C899-2D4C-4EF0-8679-C4A86D37F3CB",
              "rep" : 5,
              "recordedDistance" : 0,
              "seconds" : 240,
              "percentage" : 0,
              "styleId" : "",
              "index" : 0
            }
          ]
        },
        {
          "id" : "2",
          "reps" : [
            {
              "typeId" : "work",
              "set" : 2,
              "isComplete" : false,
              "id" : "36E20B7D-DC15-4821-94AE-84EAC409823E",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 2,
              "isComplete" : false,
              "id" : "AC69DECF-9A1D-4F19-9418-A32152B04BD2",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.5,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 2,
              "isComplete" : false,
              "id" : "DDCB2609-263A-402B-8C45-29C2D083DA70",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 2,
              "isComplete" : false,
              "id" : "C9CEC5E7-851B-4D81-BAD3-9F79454F31D0",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.5,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 2,
              "isComplete" : false,
              "id" : "742A2C7E-5432-4EA8-8184-679BA6675F1C",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 2,
              "isComplete" : false,
              "id" : "E5EC8492-D66F-4574-AC47-8E52E1983F94",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.5,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 2,
              "isComplete" : false,
              "id" : "B1EE8593-FEEF-4716-A64E-E755912368FC",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 2,
              "isComplete" : false,
              "id" : "98382219-986E-4A8B-87E6-C2F0DD102DFF",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.5,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 2,
              "isComplete" : false,
              "id" : "ACEBDA88-0EE4-41EB-971E-B0C115BF699A",
              "rep" : 4,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 2,
              "isComplete" : false,
              "id" : "2C405F07-B731-44A0-8FFF-9269C19CDF0C",
              "rep" : 4,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.5,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 2,
              "isComplete" : false,
              "id" : "A220E004-01A3-49E8-93F1-F13EC92DF7DE",
              "rep" : 5,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 2,
              "isComplete" : false,
              "id" : "6321424D-FF9B-4228-8006-B8FA0A8C6188",
              "rep" : 5,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.5,
              "styleId" : "",
              "index" : 0
            }
          ]
        }
      ],
      "_type" : "setsAndReps"
    },
    {
      "id" : "conditioning-8",
      "index" : 8,
      "timeTrial" : null,
      "weekId" : 0,
      "clubId" : "",
      "typeId" : 0,
      "editedAt" : 1615265214,
      "challengeTypeId" : 0,
      "programId" : "",
      "rest" : 240,
      "isLastSession" : true,
      "intervals" : [
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "E1997324-2F52-4C39-9845-00E67680CC19",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 180,
          "percentage" : 0.84999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "22F3FEF1-71A0-42B6-A879-0797136DA3CC",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.29999999999999999,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "2982B24A-A13B-4B29-85BC-8127DEBCA993",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 180,
          "percentage" : 0.84999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "9E27C634-C3A5-4CE5-97A6-D440065F9CF2",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.29999999999999999,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "869EF3F4-F8A5-4CAD-A89A-813E9A79547F",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 180,
          "percentage" : 0.84999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "D6752112-F3BB-4517-83FF-34813E5FB145",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.29999999999999999,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "26D13DC6-DE49-4380-A2DE-EF40A974F3F9",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 180,
          "percentage" : 0.84999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "40DBBBEB-4437-4BDF-9659-9C6F8C6FB6BA",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.29999999999999999,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "rest",
          "set" : 0,
          "isComplete" : false,
          "id" : "C4A8E663-E85A-47B7-81DD-76713BA01BCE",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 240,
          "percentage" : 0,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "419BE8B2-F07B-46B1-85E6-CDAF765E3B54",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 180,
          "percentage" : 0.84999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "4449728F-D7EB-4BA8-B9E8-B169A6530FCA",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.29999999999999999,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "6082C2AB-B47C-4D31-8315-91CFB1295ECF",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 180,
          "percentage" : 0.84999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "543AA447-979C-4864-A6C2-244F91FD8F8B",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.29999999999999999,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "846667A7-00D7-45CD-B318-9E66B3FAF921",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 180,
          "percentage" : 0.84999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "A7B864B8-C948-4F04-8867-F2AEDC66A5DE",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.29999999999999999,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "993203B3-5E54-410B-A605-E641BDA6ECE1",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 180,
          "percentage" : 0.84999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "77DED604-9B64-408F-8330-20A86CE56803",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.29999999999999999,
          "styleId" : "",
          "index" : 0
        }
      ],
      "sets" : [
        {
          "id" : "0",
          "reps" : [
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "E1997324-2F52-4C39-9845-00E67680CC19",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 180,
              "percentage" : 0.84999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "22F3FEF1-71A0-42B6-A879-0797136DA3CC",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.29999999999999999,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "2982B24A-A13B-4B29-85BC-8127DEBCA993",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 180,
              "percentage" : 0.84999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "9E27C634-C3A5-4CE5-97A6-D440065F9CF2",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.29999999999999999,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "869EF3F4-F8A5-4CAD-A89A-813E9A79547F",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 180,
              "percentage" : 0.84999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "D6752112-F3BB-4517-83FF-34813E5FB145",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.29999999999999999,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "26D13DC6-DE49-4380-A2DE-EF40A974F3F9",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 180,
              "percentage" : 0.84999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "40DBBBEB-4437-4BDF-9659-9C6F8C6FB6BA",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.29999999999999999,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "rest",
              "set" : 0,
              "isComplete" : false,
              "id" : "C4A8E663-E85A-47B7-81DD-76713BA01BCE",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 240,
              "percentage" : 0,
              "styleId" : "",
              "index" : 0
            }
          ]
        },
        {
          "id" : "1",
          "reps" : [
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "419BE8B2-F07B-46B1-85E6-CDAF765E3B54",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 180,
              "percentage" : 0.84999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "4449728F-D7EB-4BA8-B9E8-B169A6530FCA",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.29999999999999999,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "6082C2AB-B47C-4D31-8315-91CFB1295ECF",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 180,
              "percentage" : 0.84999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "543AA447-979C-4864-A6C2-244F91FD8F8B",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.29999999999999999,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "846667A7-00D7-45CD-B318-9E66B3FAF921",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 180,
              "percentage" : 0.84999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "A7B864B8-C948-4F04-8867-F2AEDC66A5DE",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.29999999999999999,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "993203B3-5E54-410B-A605-E641BDA6ECE1",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 180,
              "percentage" : 0.84999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "77DED604-9B64-408F-8330-20A86CE56803",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.29999999999999999,
              "styleId" : "",
              "index" : 0
            }
          ]
        }
      ],
      "_type" : "setsAndReps"
    },
    {
      "id" : "conditioning-9",
      "index" : 9,
      "timeTrial" : null,
      "weekId" : 0,
      "clubId" : "",
      "typeId" : 0,
      "editedAt" : 1615265214,
      "challengeTypeId" : 0,
      "programId" : "",
      "rest" : 180,
      "isLastSession" : true,
      "intervals" : [
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "84C88804-716F-43EC-BFE3-05A997E2C1F2",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "971E4D86-164F-4D55-82B7-359F3D507CDC",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "01F651D9-83C8-48C4-9220-BDB6D491AB1E",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "F8E91C31-6216-4E07-BFBF-F959279F7D6E",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "716F201D-489C-4607-BBF1-B4B9FB7BAF69",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "9A222517-4773-44EE-B6F0-234AE1309157",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "5672DF83-82EB-4377-8A16-F356CFA4747A",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "1405D1E5-BD44-461A-893E-F00DC76D4AB9",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "E37CC1B0-CC23-4D8F-A947-1CF23497EF24",
          "rep" : 4,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "6A04D1CF-44FD-418F-8455-EEC3C0D1FC69",
          "rep" : 4,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "rest",
          "set" : 0,
          "isComplete" : false,
          "id" : "7C73BCF8-F691-4B74-8C16-D0C1B27A6DF7",
          "rep" : 4,
          "recordedDistance" : 0,
          "seconds" : 240,
          "percentage" : 0,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "420AC474-71D4-4008-B529-FE907D067070",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "AA2D40F5-3FBB-433D-9312-E09156344936",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "A70533F6-BB98-4C8B-B4E7-C983D579B183",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "13563AB3-1653-450C-AFDC-69DDFA4C2E97",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "60290EF2-643F-4216-8173-68E0CB80A82F",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "8AD21144-6357-45B9-9499-4CE607CB9CDE",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "48CD3532-7E4A-4737-AC9C-60902E7AE7A2",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "6387C1B0-54E2-4593-BAC2-0BC4D35D834A",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "071E0278-B8FC-44D9-8ACA-BBA091A825E5",
          "rep" : 4,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "FE4153B6-9A33-44AB-AA12-E9C05A646116",
          "rep" : 4,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "rest",
          "set" : 1,
          "isComplete" : false,
          "id" : "91EF6863-D3F6-4BC1-A173-B379930F9A2A",
          "rep" : 4,
          "recordedDistance" : 0,
          "seconds" : 240,
          "percentage" : 0,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 2,
          "isComplete" : false,
          "id" : "A3A1FAFE-4B1B-44F7-850D-6675EF156D78",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 2,
          "isComplete" : false,
          "id" : "9F08550A-0CA9-4B3F-AB91-BC9EDD1046E1",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 2,
          "isComplete" : false,
          "id" : "59454D89-4685-40FF-AAA5-DF1152972F82",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 2,
          "isComplete" : false,
          "id" : "254AA7AC-88AD-4C06-ABEE-0F3F6711686E",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 2,
          "isComplete" : false,
          "id" : "9624E21D-F9B0-4AFC-A7DC-2926D945E5C7",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 2,
          "isComplete" : false,
          "id" : "A52EB0E0-4B15-4FE5-B766-FA42125B667D",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 2,
          "isComplete" : false,
          "id" : "553841B5-A81A-439C-B410-2B895DE3189C",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 2,
          "isComplete" : false,
          "id" : "265B7294-B8C1-426D-8B2C-499779A317A1",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 2,
          "isComplete" : false,
          "id" : "47C23A06-B2ED-4D89-82D4-F6A9AAF93D70",
          "rep" : 4,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 2,
          "isComplete" : false,
          "id" : "3CA794E5-6947-42C3-AE53-9A378CFF2DB7",
          "rep" : 4,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        }
      ],
      "sets" : [
        {
          "id" : "0",
          "reps" : [
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "84C88804-716F-43EC-BFE3-05A997E2C1F2",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "971E4D86-164F-4D55-82B7-359F3D507CDC",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "01F651D9-83C8-48C4-9220-BDB6D491AB1E",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "F8E91C31-6216-4E07-BFBF-F959279F7D6E",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "716F201D-489C-4607-BBF1-B4B9FB7BAF69",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "9A222517-4773-44EE-B6F0-234AE1309157",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "5672DF83-82EB-4377-8A16-F356CFA4747A",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "1405D1E5-BD44-461A-893E-F00DC76D4AB9",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "E37CC1B0-CC23-4D8F-A947-1CF23497EF24",
              "rep" : 4,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "6A04D1CF-44FD-418F-8455-EEC3C0D1FC69",
              "rep" : 4,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "rest",
              "set" : 0,
              "isComplete" : false,
              "id" : "7C73BCF8-F691-4B74-8C16-D0C1B27A6DF7",
              "rep" : 4,
              "recordedDistance" : 0,
              "seconds" : 240,
              "percentage" : 0,
              "styleId" : "",
              "index" : 0
            }
          ]
        },
        {
          "id" : "1",
          "reps" : [
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "420AC474-71D4-4008-B529-FE907D067070",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "AA2D40F5-3FBB-433D-9312-E09156344936",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "A70533F6-BB98-4C8B-B4E7-C983D579B183",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "13563AB3-1653-450C-AFDC-69DDFA4C2E97",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "60290EF2-643F-4216-8173-68E0CB80A82F",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "8AD21144-6357-45B9-9499-4CE607CB9CDE",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "48CD3532-7E4A-4737-AC9C-60902E7AE7A2",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "6387C1B0-54E2-4593-BAC2-0BC4D35D834A",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "071E0278-B8FC-44D9-8ACA-BBA091A825E5",
              "rep" : 4,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "FE4153B6-9A33-44AB-AA12-E9C05A646116",
              "rep" : 4,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "rest",
              "set" : 1,
              "isComplete" : false,
              "id" : "91EF6863-D3F6-4BC1-A173-B379930F9A2A",
              "rep" : 4,
              "recordedDistance" : 0,
              "seconds" : 240,
              "percentage" : 0,
              "styleId" : "",
              "index" : 0
            }
          ]
        },
        {
          "id" : "2",
          "reps" : [
            {
              "typeId" : "work",
              "set" : 2,
              "isComplete" : false,
              "id" : "A3A1FAFE-4B1B-44F7-850D-6675EF156D78",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 2,
              "isComplete" : false,
              "id" : "9F08550A-0CA9-4B3F-AB91-BC9EDD1046E1",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 2,
              "isComplete" : false,
              "id" : "59454D89-4685-40FF-AAA5-DF1152972F82",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 2,
              "isComplete" : false,
              "id" : "254AA7AC-88AD-4C06-ABEE-0F3F6711686E",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 2,
              "isComplete" : false,
              "id" : "9624E21D-F9B0-4AFC-A7DC-2926D945E5C7",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 2,
              "isComplete" : false,
              "id" : "A52EB0E0-4B15-4FE5-B766-FA42125B667D",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 2,
              "isComplete" : false,
              "id" : "553841B5-A81A-439C-B410-2B895DE3189C",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 2,
              "isComplete" : false,
              "id" : "265B7294-B8C1-426D-8B2C-499779A317A1",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 2,
              "isComplete" : false,
              "id" : "47C23A06-B2ED-4D89-82D4-F6A9AAF93D70",
              "rep" : 4,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 2,
              "isComplete" : false,
              "id" : "3CA794E5-6947-42C3-AE53-9A378CFF2DB7",
              "rep" : 4,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            }
          ]
        }
      ],
      "_type" : "setsAndReps"
    },
    {
      "id" : "conditioning-10",
      "index" : 10,
      "timeTrial" : null,
      "weekId" : 0,
      "clubId" : "",
      "typeId" : 0,
      "editedAt" : 1615265214,
      "challengeTypeId" : 0,
      "programId" : "",
      "rest" : 180,
      "isLastSession" : true,
      "intervals" : [
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "2DC51A8B-DFDA-4C29-91EA-BA376F06954A",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 120,
          "percentage" : 0.90000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "967FEBC8-EE14-4F16-8738-3F19E47C33CD",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.40000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "20A535F8-FE77-4949-8ADD-556D61593E6A",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 120,
          "percentage" : 0.90000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "01370413-A3B8-4740-9659-D683461B1590",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.40000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "D1266A81-0799-48EF-B1FB-9FE36FB32622",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 120,
          "percentage" : 0.90000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "41E3F2B2-06CB-47A8-A0DF-95A6A5D98953",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.40000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "B59DFE5B-F7A4-42ED-8173-4C0E8DF47F49",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 120,
          "percentage" : 0.90000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "A4324B3A-6957-4CCE-B263-C49AE71402D6",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.40000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "E8238F8C-91CF-4AE9-8352-4CF75C4B9E5E",
          "rep" : 4,
          "recordedDistance" : 0,
          "seconds" : 120,
          "percentage" : 0.90000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "2334B389-DEAD-4CA1-83BF-336E7EA61C0D",
          "rep" : 4,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.40000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "rest",
          "set" : 0,
          "isComplete" : false,
          "id" : "9151DC26-1C9D-4B01-8A95-9E4E7556C2B3",
          "rep" : 4,
          "recordedDistance" : 0,
          "seconds" : 240,
          "percentage" : 0,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "C05D5549-A05E-4E00-A463-9F553962305B",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 120,
          "percentage" : 0.90000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "6B526092-07B9-446E-BA2A-97485EC40EFC",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.40000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "2679B093-5608-47F5-B347-DD723AD59057",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 120,
          "percentage" : 0.90000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "7FFD6DBC-B1D4-44FD-BFB7-4130C3D76545",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.40000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "E17B072C-6BDB-4B48-842D-2F6890BC3FE7",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 120,
          "percentage" : 0.90000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "DB58C851-3C16-4BA2-B54C-9267DECD872C",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.40000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "5EAEE39D-EDCA-4818-A834-B495AD021C5E",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 120,
          "percentage" : 0.90000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "911A12A2-ADDA-45B8-BB75-2413E80D300E",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.40000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "FE836239-98DB-443E-997A-DC989C47BD00",
          "rep" : 4,
          "recordedDistance" : 0,
          "seconds" : 120,
          "percentage" : 0.90000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "1E1F29CE-991B-4510-BA5D-6F2EAA6EF918",
          "rep" : 4,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.40000000000000002,
          "styleId" : "",
          "index" : 0
        }
      ],
      "sets" : [
        {
          "id" : "0",
          "reps" : [
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "2DC51A8B-DFDA-4C29-91EA-BA376F06954A",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 120,
              "percentage" : 0.90000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "967FEBC8-EE14-4F16-8738-3F19E47C33CD",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.40000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "20A535F8-FE77-4949-8ADD-556D61593E6A",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 120,
              "percentage" : 0.90000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "01370413-A3B8-4740-9659-D683461B1590",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.40000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "D1266A81-0799-48EF-B1FB-9FE36FB32622",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 120,
              "percentage" : 0.90000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "41E3F2B2-06CB-47A8-A0DF-95A6A5D98953",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.40000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "B59DFE5B-F7A4-42ED-8173-4C0E8DF47F49",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 120,
              "percentage" : 0.90000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "A4324B3A-6957-4CCE-B263-C49AE71402D6",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.40000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "E8238F8C-91CF-4AE9-8352-4CF75C4B9E5E",
              "rep" : 4,
              "recordedDistance" : 0,
              "seconds" : 120,
              "percentage" : 0.90000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "2334B389-DEAD-4CA1-83BF-336E7EA61C0D",
              "rep" : 4,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.40000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "rest",
              "set" : 0,
              "isComplete" : false,
              "id" : "9151DC26-1C9D-4B01-8A95-9E4E7556C2B3",
              "rep" : 4,
              "recordedDistance" : 0,
              "seconds" : 240,
              "percentage" : 0,
              "styleId" : "",
              "index" : 0
            }
          ]
        },
        {
          "id" : "1",
          "reps" : [
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "C05D5549-A05E-4E00-A463-9F553962305B",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 120,
              "percentage" : 0.90000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "6B526092-07B9-446E-BA2A-97485EC40EFC",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.40000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "2679B093-5608-47F5-B347-DD723AD59057",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 120,
              "percentage" : 0.90000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "7FFD6DBC-B1D4-44FD-BFB7-4130C3D76545",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.40000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "E17B072C-6BDB-4B48-842D-2F6890BC3FE7",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 120,
              "percentage" : 0.90000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "DB58C851-3C16-4BA2-B54C-9267DECD872C",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.40000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "5EAEE39D-EDCA-4818-A834-B495AD021C5E",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 120,
              "percentage" : 0.90000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "911A12A2-ADDA-45B8-BB75-2413E80D300E",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.40000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "FE836239-98DB-443E-997A-DC989C47BD00",
              "rep" : 4,
              "recordedDistance" : 0,
              "seconds" : 120,
              "percentage" : 0.90000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "1E1F29CE-991B-4510-BA5D-6F2EAA6EF918",
              "rep" : 4,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.40000000000000002,
              "styleId" : "",
              "index" : 0
            }
          ]
        }
      ],
      "_type" : "setsAndReps"
    },
    {
      "id" : "conditioning-11",
      "index" : 11,
      "timeTrial" : null,
      "weekId" : 0,
      "clubId" : "",
      "typeId" : 0,
      "editedAt" : 1615265214,
      "challengeTypeId" : 0,
      "programId" : "",
      "rest" : 180,
      "isLastSession" : true,
      "intervals" : [
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "84C88804-716F-43EC-BFE3-05A997E2C1F2",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "971E4D86-164F-4D55-82B7-359F3D507CDC",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "01F651D9-83C8-48C4-9220-BDB6D491AB1E",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "F8E91C31-6216-4E07-BFBF-F959279F7D6E",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "716F201D-489C-4607-BBF1-B4B9FB7BAF69",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "9A222517-4773-44EE-B6F0-234AE1309157",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "5672DF83-82EB-4377-8A16-F356CFA4747A",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "1405D1E5-BD44-461A-893E-F00DC76D4AB9",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "E37CC1B0-CC23-4D8F-A947-1CF23497EF24",
          "rep" : 4,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "6A04D1CF-44FD-418F-8455-EEC3C0D1FC69",
          "rep" : 4,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "rest",
          "set" : 0,
          "isComplete" : false,
          "id" : "7C73BCF8-F691-4B74-8C16-D0C1B27A6DF7",
          "rep" : 4,
          "recordedDistance" : 0,
          "seconds" : 240,
          "percentage" : 0,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "420AC474-71D4-4008-B529-FE907D067070",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "AA2D40F5-3FBB-433D-9312-E09156344936",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "A70533F6-BB98-4C8B-B4E7-C983D579B183",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "13563AB3-1653-450C-AFDC-69DDFA4C2E97",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "60290EF2-643F-4216-8173-68E0CB80A82F",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "8AD21144-6357-45B9-9499-4CE607CB9CDE",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "48CD3532-7E4A-4737-AC9C-60902E7AE7A2",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "6387C1B0-54E2-4593-BAC2-0BC4D35D834A",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "071E0278-B8FC-44D9-8ACA-BBA091A825E5",
          "rep" : 4,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "FE4153B6-9A33-44AB-AA12-E9C05A646116",
          "rep" : 4,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "rest",
          "set" : 1,
          "isComplete" : false,
          "id" : "91EF6863-D3F6-4BC1-A173-B379930F9A2A",
          "rep" : 4,
          "recordedDistance" : 0,
          "seconds" : 240,
          "percentage" : 0,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 2,
          "isComplete" : false,
          "id" : "A3A1FAFE-4B1B-44F7-850D-6675EF156D78",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 2,
          "isComplete" : false,
          "id" : "9F08550A-0CA9-4B3F-AB91-BC9EDD1046E1",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 2,
          "isComplete" : false,
          "id" : "59454D89-4685-40FF-AAA5-DF1152972F82",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 2,
          "isComplete" : false,
          "id" : "254AA7AC-88AD-4C06-ABEE-0F3F6711686E",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 2,
          "isComplete" : false,
          "id" : "9624E21D-F9B0-4AFC-A7DC-2926D945E5C7",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 2,
          "isComplete" : false,
          "id" : "A52EB0E0-4B15-4FE5-B766-FA42125B667D",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 2,
          "isComplete" : false,
          "id" : "553841B5-A81A-439C-B410-2B895DE3189C",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 2,
          "isComplete" : false,
          "id" : "265B7294-B8C1-426D-8B2C-499779A317A1",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 2,
          "isComplete" : false,
          "id" : "47C23A06-B2ED-4D89-82D4-F6A9AAF93D70",
          "rep" : 4,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 2,
          "isComplete" : false,
          "id" : "3CA794E5-6947-42C3-AE53-9A378CFF2DB7",
          "rep" : 4,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        }
      ],
      "sets" : [
        {
          "id" : "0",
          "reps" : [
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "84C88804-716F-43EC-BFE3-05A997E2C1F2",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "971E4D86-164F-4D55-82B7-359F3D507CDC",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "01F651D9-83C8-48C4-9220-BDB6D491AB1E",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "F8E91C31-6216-4E07-BFBF-F959279F7D6E",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "716F201D-489C-4607-BBF1-B4B9FB7BAF69",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "9A222517-4773-44EE-B6F0-234AE1309157",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "5672DF83-82EB-4377-8A16-F356CFA4747A",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "1405D1E5-BD44-461A-893E-F00DC76D4AB9",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "E37CC1B0-CC23-4D8F-A947-1CF23497EF24",
              "rep" : 4,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "6A04D1CF-44FD-418F-8455-EEC3C0D1FC69",
              "rep" : 4,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "rest",
              "set" : 0,
              "isComplete" : false,
              "id" : "7C73BCF8-F691-4B74-8C16-D0C1B27A6DF7",
              "rep" : 4,
              "recordedDistance" : 0,
              "seconds" : 240,
              "percentage" : 0,
              "styleId" : "",
              "index" : 0
            }
          ]
        },
        {
          "id" : "1",
          "reps" : [
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "420AC474-71D4-4008-B529-FE907D067070",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "AA2D40F5-3FBB-433D-9312-E09156344936",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "A70533F6-BB98-4C8B-B4E7-C983D579B183",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "13563AB3-1653-450C-AFDC-69DDFA4C2E97",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "60290EF2-643F-4216-8173-68E0CB80A82F",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "8AD21144-6357-45B9-9499-4CE607CB9CDE",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "48CD3532-7E4A-4737-AC9C-60902E7AE7A2",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "6387C1B0-54E2-4593-BAC2-0BC4D35D834A",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "071E0278-B8FC-44D9-8ACA-BBA091A825E5",
              "rep" : 4,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "FE4153B6-9A33-44AB-AA12-E9C05A646116",
              "rep" : 4,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "rest",
              "set" : 1,
              "isComplete" : false,
              "id" : "91EF6863-D3F6-4BC1-A173-B379930F9A2A",
              "rep" : 4,
              "recordedDistance" : 0,
              "seconds" : 240,
              "percentage" : 0,
              "styleId" : "",
              "index" : 0
            }
          ]
        },
        {
          "id" : "2",
          "reps" : [
            {
              "typeId" : "work",
              "set" : 2,
              "isComplete" : false,
              "id" : "A3A1FAFE-4B1B-44F7-850D-6675EF156D78",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 2,
              "isComplete" : false,
              "id" : "9F08550A-0CA9-4B3F-AB91-BC9EDD1046E1",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 2,
              "isComplete" : false,
              "id" : "59454D89-4685-40FF-AAA5-DF1152972F82",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 2,
              "isComplete" : false,
              "id" : "254AA7AC-88AD-4C06-ABEE-0F3F6711686E",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 2,
              "isComplete" : false,
              "id" : "9624E21D-F9B0-4AFC-A7DC-2926D945E5C7",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 2,
              "isComplete" : false,
              "id" : "A52EB0E0-4B15-4FE5-B766-FA42125B667D",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 2,
              "isComplete" : false,
              "id" : "553841B5-A81A-439C-B410-2B895DE3189C",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 2,
              "isComplete" : false,
              "id" : "265B7294-B8C1-426D-8B2C-499779A317A1",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 2,
              "isComplete" : false,
              "id" : "47C23A06-B2ED-4D89-82D4-F6A9AAF93D70",
              "rep" : 4,
              "recordedDistance" : 0,
              "seconds" : 0,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 2,
              "isComplete" : false,
              "id" : "3CA794E5-6947-42C3-AE53-9A378CFF2DB7",
              "rep" : 4,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            }
          ]
        }
      ],
      "_type" : "setsAndReps"
    },
    {
      "id" : "conditioning-12",
      "index" : 12,
      "timeTrial" : null,
      "weekId" : 0,
      "clubId" : "",
      "typeId" : 0,
      "editedAt" : 1615265214,
      "challengeTypeId" : 0,
      "programId" : "",
      "rest" : 180,
      "isLastSession" : true,
      "intervals" : [
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "E8BE316D-69D6-4249-A77A-92D6B0AB9071",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 120,
          "percentage" : 0.90000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "BF58AA14-740C-4DAC-BD12-A5E47DBBB45E",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.40000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "5CBD6978-DA07-4B6F-8417-D0E660823726",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 120,
          "percentage" : 0.90000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "914699E8-F5D6-4019-AD5C-09D29E0DBF43",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.40000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "DEDBCDB3-93FF-4ADA-9DC1-9FAC7BA85E70",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 120,
          "percentage" : 0.90000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "E6710BCA-B35A-4B1A-B130-D9D864266EDD",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.40000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "44F5A3E6-DC32-4B29-8758-C43458BBFFED",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 120,
          "percentage" : 0.90000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "7A0964A4-EDF3-4859-AF49-3F18A42A5FAB",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.40000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "5600EB9F-35CD-4CB9-9EAF-B729C7DB13A2",
          "rep" : 4,
          "recordedDistance" : 0,
          "seconds" : 120,
          "percentage" : 0.90000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "4E106557-C1A4-4C87-BAB9-E43C3C1F7ED4",
          "rep" : 4,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.40000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "8F232AE2-8554-4F96-B26D-6B61052AC543",
          "rep" : 5,
          "recordedDistance" : 0,
          "seconds" : 120,
          "percentage" : 0.90000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "DF0BCEC5-4402-48CB-AF8B-B1B2C955B24D",
          "rep" : 5,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.40000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "rest",
          "set" : 0,
          "isComplete" : false,
          "id" : "180688B7-A767-4B06-AF11-8554557282E5",
          "rep" : 5,
          "recordedDistance" : 0,
          "seconds" : 240,
          "percentage" : 0,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "EEC1FF5B-A8A7-4406-BED7-E51932D08559",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 120,
          "percentage" : 0.90000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "C66FFCFF-F2C6-4004-9514-B9599132B537",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.40000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "FB2538A9-24C7-408F-9070-9706FA892571",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 120,
          "percentage" : 0.90000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "F6B977CA-C200-42DA-9F63-4F88FC8E28CF",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.40000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "B74570CA-D06F-47F0-AEBB-0DDFBC3A6CD1",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 120,
          "percentage" : 0.90000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "8D37911C-6510-4B39-AAA8-093020A546A0",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.40000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "96F182F1-8EDD-4779-83BE-805A6ED4F52A",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 120,
          "percentage" : 0.90000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "87F151C2-9010-4AC5-B038-F173F772CA8F",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.40000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "2B64B9C7-96BD-4994-86A5-83D52D0BE4D2",
          "rep" : 4,
          "recordedDistance" : 0,
          "seconds" : 120,
          "percentage" : 0.90000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "AB1AFE9D-617A-4E27-B669-151AC4C33FC7",
          "rep" : 4,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.40000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "3B39507E-B135-4917-85F4-634D31A70DDF",
          "rep" : 5,
          "recordedDistance" : 0,
          "seconds" : 120,
          "percentage" : 0.90000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "808A64E2-BB7D-40AB-A262-C2FAAA2AFD64",
          "rep" : 5,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.40000000000000002,
          "styleId" : "",
          "index" : 0
        }
      ],
      "sets" : [
        {
          "id" : "0",
          "reps" : [
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "E8BE316D-69D6-4249-A77A-92D6B0AB9071",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 120,
              "percentage" : 0.90000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "BF58AA14-740C-4DAC-BD12-A5E47DBBB45E",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.40000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "5CBD6978-DA07-4B6F-8417-D0E660823726",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 120,
              "percentage" : 0.90000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "914699E8-F5D6-4019-AD5C-09D29E0DBF43",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.40000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "DEDBCDB3-93FF-4ADA-9DC1-9FAC7BA85E70",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 120,
              "percentage" : 0.90000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "E6710BCA-B35A-4B1A-B130-D9D864266EDD",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.40000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "44F5A3E6-DC32-4B29-8758-C43458BBFFED",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 120,
              "percentage" : 0.90000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "7A0964A4-EDF3-4859-AF49-3F18A42A5FAB",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.40000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "5600EB9F-35CD-4CB9-9EAF-B729C7DB13A2",
              "rep" : 4,
              "recordedDistance" : 0,
              "seconds" : 120,
              "percentage" : 0.90000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "4E106557-C1A4-4C87-BAB9-E43C3C1F7ED4",
              "rep" : 4,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.40000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "8F232AE2-8554-4F96-B26D-6B61052AC543",
              "rep" : 5,
              "recordedDistance" : 0,
              "seconds" : 120,
              "percentage" : 0.90000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "DF0BCEC5-4402-48CB-AF8B-B1B2C955B24D",
              "rep" : 5,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.40000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "rest",
              "set" : 0,
              "isComplete" : false,
              "id" : "180688B7-A767-4B06-AF11-8554557282E5",
              "rep" : 5,
              "recordedDistance" : 0,
              "seconds" : 240,
              "percentage" : 0,
              "styleId" : "",
              "index" : 0
            }
          ]
        },
        {
          "id" : "1",
          "reps" : [
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "EEC1FF5B-A8A7-4406-BED7-E51932D08559",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 120,
              "percentage" : 0.90000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "C66FFCFF-F2C6-4004-9514-B9599132B537",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.40000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "FB2538A9-24C7-408F-9070-9706FA892571",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 120,
              "percentage" : 0.90000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "F6B977CA-C200-42DA-9F63-4F88FC8E28CF",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.40000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "B74570CA-D06F-47F0-AEBB-0DDFBC3A6CD1",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 120,
              "percentage" : 0.90000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "8D37911C-6510-4B39-AAA8-093020A546A0",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.40000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "96F182F1-8EDD-4779-83BE-805A6ED4F52A",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 120,
              "percentage" : 0.90000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "87F151C2-9010-4AC5-B038-F173F772CA8F",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.40000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "2B64B9C7-96BD-4994-86A5-83D52D0BE4D2",
              "rep" : 4,
              "recordedDistance" : 0,
              "seconds" : 120,
              "percentage" : 0.90000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "AB1AFE9D-617A-4E27-B669-151AC4C33FC7",
              "rep" : 4,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.40000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "3B39507E-B135-4917-85F4-634D31A70DDF",
              "rep" : 5,
              "recordedDistance" : 0,
              "seconds" : 120,
              "percentage" : 0.90000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "808A64E2-BB7D-40AB-A262-C2FAAA2AFD64",
              "rep" : 5,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.40000000000000002,
              "styleId" : "",
              "index" : 0
            }
          ]
        }
      ],
      "_type" : "setsAndReps"
    },
    {
      "id" : "conditioning-13",
      "index" : 13,
      "timeTrial" : null,
      "weekId" : 0,
      "clubId" : "",
      "typeId" : 0,
      "editedAt" : 1615265214,
      "challengeTypeId" : 0,
      "programId" : "",
      "rest" : 180,
      "isLastSession" : true,
      "intervals" : [
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "1CAB99F3-99E6-41ED-B946-1FE20526E080",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "CF944307-F299-4933-962D-E30F535A0513",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "3029F82C-70E3-4386-BE3C-5AA90EDEDAA3",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "A8F6080B-6AC6-4A18-A55E-FE0B37B846E7",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "DBFAE7E5-A1A5-4FF0-AC5C-32A1580F1FC1",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "1C734ECA-E6E3-4D1D-B4BC-1448F831DB43",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "2214D799-00FE-4DEA-8BBA-02A136878BAB",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "2C2B31FE-6F3D-4606-A23D-E7D36BDAE6E4",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "1E6AC9DE-7D79-40EB-AA15-D08319132B56",
          "rep" : 4,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "B916C2B3-6802-4172-B1D8-77E2F8E39185",
          "rep" : 4,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "B02B44E0-8267-4B07-AF1A-8F67DF33778B",
          "rep" : 5,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "D2C556C0-6C8E-4B67-AA30-44F575E01C36",
          "rep" : 5,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "rest",
          "set" : 0,
          "isComplete" : false,
          "id" : "D5CF0CC3-0C62-4C35-92D3-B7F7AE90F26F",
          "rep" : 5,
          "recordedDistance" : 0,
          "seconds" : 240,
          "percentage" : 0,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "E15E9599-F2DE-4CAD-A95C-9B693083C99F",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "6F416464-2BCE-4DDF-BF61-3632BA717B33",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "164244E8-FB76-4DCA-A72F-AEC3C784229A",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "D60EF434-1699-4D44-A5FC-9C6C35FA1DE0",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "64CC0134-AA1D-4658-AC63-0DB1F89E3B7C",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "F9405F20-C414-45F2-AEA6-1A9692A7743A",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "6759E316-7190-40F0-A4BC-813C1C030409",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "393F60DD-0A28-4D1A-898E-C49A418B16CA",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "16798899-BAD0-4B7D-8C2D-2DE55310435D",
          "rep" : 4,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "6DDD9E94-2F40-4BA4-A815-DAF10FD2E915",
          "rep" : 4,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "B5B9A1C6-CAB1-4FAE-BDBA-D683B5C87423",
          "rep" : 5,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "7E44035F-8576-419A-9E6E-7FB3926D592E",
          "rep" : 5,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "rest",
          "set" : 1,
          "isComplete" : false,
          "id" : "C21CBDE1-678B-4C0F-B075-09E909B95AEB",
          "rep" : 5,
          "recordedDistance" : 0,
          "seconds" : 240,
          "percentage" : 0,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 2,
          "isComplete" : false,
          "id" : "93BC2FDD-0874-42C9-96F5-42FFED89E5F7",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 2,
          "isComplete" : false,
          "id" : "CC41470E-3937-4429-8D39-D6EA33343911",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 2,
          "isComplete" : false,
          "id" : "D867790C-4E30-4590-81B8-744FFDF2F4D7",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 2,
          "isComplete" : false,
          "id" : "F797A758-FEEC-4833-A8B5-CB7C598EA481",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 2,
          "isComplete" : false,
          "id" : "21FC2A96-E62A-46AF-BD5E-0B2F89F28162",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 2,
          "isComplete" : false,
          "id" : "87ED95B4-3806-46A8-BB84-491D540FB843",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 2,
          "isComplete" : false,
          "id" : "55321E62-C610-4013-80EB-1C1A30644814",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 2,
          "isComplete" : false,
          "id" : "8AF100F1-94D7-483A-ADFE-11B70A9AD87B",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 2,
          "isComplete" : false,
          "id" : "007DA129-386C-440A-ACE1-B032CE8E5D67",
          "rep" : 4,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 2,
          "isComplete" : false,
          "id" : "B7B69AE7-2E18-41B5-88E1-4E2B5BAC6E3C",
          "rep" : 4,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 2,
          "isComplete" : false,
          "id" : "45842ECC-4C99-41C5-B7C7-52F5C4A80C33",
          "rep" : 5,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 2,
          "isComplete" : false,
          "id" : "55CE3BB3-F954-4F76-B80F-68B01A036962",
          "rep" : 5,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        }
      ],
      "sets" : [
        {
          "id" : "0",
          "reps" : [
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "1CAB99F3-99E6-41ED-B946-1FE20526E080",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "CF944307-F299-4933-962D-E30F535A0513",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "3029F82C-70E3-4386-BE3C-5AA90EDEDAA3",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "A8F6080B-6AC6-4A18-A55E-FE0B37B846E7",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "DBFAE7E5-A1A5-4FF0-AC5C-32A1580F1FC1",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "1C734ECA-E6E3-4D1D-B4BC-1448F831DB43",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "2214D799-00FE-4DEA-8BBA-02A136878BAB",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "2C2B31FE-6F3D-4606-A23D-E7D36BDAE6E4",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "1E6AC9DE-7D79-40EB-AA15-D08319132B56",
              "rep" : 4,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "B916C2B3-6802-4172-B1D8-77E2F8E39185",
              "rep" : 4,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "B02B44E0-8267-4B07-AF1A-8F67DF33778B",
              "rep" : 5,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "D2C556C0-6C8E-4B67-AA30-44F575E01C36",
              "rep" : 5,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "rest",
              "set" : 0,
              "isComplete" : false,
              "id" : "D5CF0CC3-0C62-4C35-92D3-B7F7AE90F26F",
              "rep" : 5,
              "recordedDistance" : 0,
              "seconds" : 240,
              "percentage" : 0,
              "styleId" : "",
              "index" : 0
            }
          ]
        },
        {
          "id" : "1",
          "reps" : [
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "E15E9599-F2DE-4CAD-A95C-9B693083C99F",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "6F416464-2BCE-4DDF-BF61-3632BA717B33",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "164244E8-FB76-4DCA-A72F-AEC3C784229A",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "D60EF434-1699-4D44-A5FC-9C6C35FA1DE0",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "64CC0134-AA1D-4658-AC63-0DB1F89E3B7C",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "F9405F20-C414-45F2-AEA6-1A9692A7743A",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "6759E316-7190-40F0-A4BC-813C1C030409",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "393F60DD-0A28-4D1A-898E-C49A418B16CA",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "16798899-BAD0-4B7D-8C2D-2DE55310435D",
              "rep" : 4,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "6DDD9E94-2F40-4BA4-A815-DAF10FD2E915",
              "rep" : 4,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "B5B9A1C6-CAB1-4FAE-BDBA-D683B5C87423",
              "rep" : 5,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "7E44035F-8576-419A-9E6E-7FB3926D592E",
              "rep" : 5,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "rest",
              "set" : 1,
              "isComplete" : false,
              "id" : "C21CBDE1-678B-4C0F-B075-09E909B95AEB",
              "rep" : 5,
              "recordedDistance" : 0,
              "seconds" : 240,
              "percentage" : 0,
              "styleId" : "",
              "index" : 0
            }
          ]
        },
        {
          "id" : "2",
          "reps" : [
            {
              "typeId" : "work",
              "set" : 2,
              "isComplete" : false,
              "id" : "93BC2FDD-0874-42C9-96F5-42FFED89E5F7",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 2,
              "isComplete" : false,
              "id" : "CC41470E-3937-4429-8D39-D6EA33343911",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 2,
              "isComplete" : false,
              "id" : "D867790C-4E30-4590-81B8-744FFDF2F4D7",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 2,
              "isComplete" : false,
              "id" : "F797A758-FEEC-4833-A8B5-CB7C598EA481",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 2,
              "isComplete" : false,
              "id" : "21FC2A96-E62A-46AF-BD5E-0B2F89F28162",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 2,
              "isComplete" : false,
              "id" : "87ED95B4-3806-46A8-BB84-491D540FB843",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 2,
              "isComplete" : false,
              "id" : "55321E62-C610-4013-80EB-1C1A30644814",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 2,
              "isComplete" : false,
              "id" : "8AF100F1-94D7-483A-ADFE-11B70A9AD87B",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 2,
              "isComplete" : false,
              "id" : "007DA129-386C-440A-ACE1-B032CE8E5D67",
              "rep" : 4,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 2,
              "isComplete" : false,
              "id" : "B7B69AE7-2E18-41B5-88E1-4E2B5BAC6E3C",
              "rep" : 4,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 2,
              "isComplete" : false,
              "id" : "45842ECC-4C99-41C5-B7C7-52F5C4A80C33",
              "rep" : 5,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 2,
              "isComplete" : false,
              "id" : "55CE3BB3-F954-4F76-B80F-68B01A036962",
              "rep" : 5,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            }
          ]
        }
      ],
      "_type" : "setsAndReps"
    },
    {
      "id" : "conditioning-14",
      "index" : 14,
      "timeTrial" : null,
      "weekId" : 0,
      "clubId" : "",
      "typeId" : 0,
      "editedAt" : 1615265214,
      "challengeTypeId" : 0,
      "programId" : "",
      "rest" : 180,
      "isLastSession" : true,
      "intervals" : [
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "E8BE316D-69D6-4249-A77A-92D6B0AB9071",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 120,
          "percentage" : 0.90000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "BF58AA14-740C-4DAC-BD12-A5E47DBBB45E",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.40000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "5CBD6978-DA07-4B6F-8417-D0E660823726",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 120,
          "percentage" : 0.90000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "914699E8-F5D6-4019-AD5C-09D29E0DBF43",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.40000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "DEDBCDB3-93FF-4ADA-9DC1-9FAC7BA85E70",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 120,
          "percentage" : 0.90000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "E6710BCA-B35A-4B1A-B130-D9D864266EDD",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.40000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "44F5A3E6-DC32-4B29-8758-C43458BBFFED",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 120,
          "percentage" : 0.90000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "7A0964A4-EDF3-4859-AF49-3F18A42A5FAB",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.40000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "5600EB9F-35CD-4CB9-9EAF-B729C7DB13A2",
          "rep" : 4,
          "recordedDistance" : 0,
          "seconds" : 120,
          "percentage" : 0.90000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "4E106557-C1A4-4C87-BAB9-E43C3C1F7ED4",
          "rep" : 4,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.40000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "8F232AE2-8554-4F96-B26D-6B61052AC543",
          "rep" : 5,
          "recordedDistance" : 0,
          "seconds" : 120,
          "percentage" : 0.90000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "DF0BCEC5-4402-48CB-AF8B-B1B2C955B24D",
          "rep" : 5,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.40000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "rest",
          "set" : 0,
          "isComplete" : false,
          "id" : "180688B7-A767-4B06-AF11-8554557282E5",
          "rep" : 5,
          "recordedDistance" : 0,
          "seconds" : 240,
          "percentage" : 0,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "EEC1FF5B-A8A7-4406-BED7-E51932D08559",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 120,
          "percentage" : 0.90000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "C66FFCFF-F2C6-4004-9514-B9599132B537",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.40000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "FB2538A9-24C7-408F-9070-9706FA892571",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 120,
          "percentage" : 0.90000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "F6B977CA-C200-42DA-9F63-4F88FC8E28CF",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.40000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "B74570CA-D06F-47F0-AEBB-0DDFBC3A6CD1",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 120,
          "percentage" : 0.90000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "8D37911C-6510-4B39-AAA8-093020A546A0",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.40000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "96F182F1-8EDD-4779-83BE-805A6ED4F52A",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 120,
          "percentage" : 0.90000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "87F151C2-9010-4AC5-B038-F173F772CA8F",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.40000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "2B64B9C7-96BD-4994-86A5-83D52D0BE4D2",
          "rep" : 4,
          "recordedDistance" : 0,
          "seconds" : 120,
          "percentage" : 0.90000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "AB1AFE9D-617A-4E27-B669-151AC4C33FC7",
          "rep" : 4,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.40000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "3B39507E-B135-4917-85F4-634D31A70DDF",
          "rep" : 5,
          "recordedDistance" : 0,
          "seconds" : 120,
          "percentage" : 0.90000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "808A64E2-BB7D-40AB-A262-C2FAAA2AFD64",
          "rep" : 5,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.40000000000000002,
          "styleId" : "",
          "index" : 0
        }
      ],
      "sets" : [
        {
          "id" : "0",
          "reps" : [
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "E8BE316D-69D6-4249-A77A-92D6B0AB9071",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 120,
              "percentage" : 0.90000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "BF58AA14-740C-4DAC-BD12-A5E47DBBB45E",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.40000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "5CBD6978-DA07-4B6F-8417-D0E660823726",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 120,
              "percentage" : 0.90000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "914699E8-F5D6-4019-AD5C-09D29E0DBF43",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.40000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "DEDBCDB3-93FF-4ADA-9DC1-9FAC7BA85E70",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 120,
              "percentage" : 0.90000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "E6710BCA-B35A-4B1A-B130-D9D864266EDD",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.40000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "44F5A3E6-DC32-4B29-8758-C43458BBFFED",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 120,
              "percentage" : 0.90000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "7A0964A4-EDF3-4859-AF49-3F18A42A5FAB",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.40000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "5600EB9F-35CD-4CB9-9EAF-B729C7DB13A2",
              "rep" : 4,
              "recordedDistance" : 0,
              "seconds" : 120,
              "percentage" : 0.90000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "4E106557-C1A4-4C87-BAB9-E43C3C1F7ED4",
              "rep" : 4,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.40000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "8F232AE2-8554-4F96-B26D-6B61052AC543",
              "rep" : 5,
              "recordedDistance" : 0,
              "seconds" : 120,
              "percentage" : 0.90000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "DF0BCEC5-4402-48CB-AF8B-B1B2C955B24D",
              "rep" : 5,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.40000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "rest",
              "set" : 0,
              "isComplete" : false,
              "id" : "180688B7-A767-4B06-AF11-8554557282E5",
              "rep" : 5,
              "recordedDistance" : 0,
              "seconds" : 240,
              "percentage" : 0,
              "styleId" : "",
              "index" : 0
            }
          ]
        },
        {
          "id" : "1",
          "reps" : [
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "EEC1FF5B-A8A7-4406-BED7-E51932D08559",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 120,
              "percentage" : 0.90000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "C66FFCFF-F2C6-4004-9514-B9599132B537",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.40000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "FB2538A9-24C7-408F-9070-9706FA892571",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 120,
              "percentage" : 0.90000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "F6B977CA-C200-42DA-9F63-4F88FC8E28CF",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.40000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "B74570CA-D06F-47F0-AEBB-0DDFBC3A6CD1",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 120,
              "percentage" : 0.90000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "8D37911C-6510-4B39-AAA8-093020A546A0",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.40000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "96F182F1-8EDD-4779-83BE-805A6ED4F52A",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 120,
              "percentage" : 0.90000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "87F151C2-9010-4AC5-B038-F173F772CA8F",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.40000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "2B64B9C7-96BD-4994-86A5-83D52D0BE4D2",
              "rep" : 4,
              "recordedDistance" : 0,
              "seconds" : 120,
              "percentage" : 0.90000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "AB1AFE9D-617A-4E27-B669-151AC4C33FC7",
              "rep" : 4,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.40000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "3B39507E-B135-4917-85F4-634D31A70DDF",
              "rep" : 5,
              "recordedDistance" : 0,
              "seconds" : 120,
              "percentage" : 0.90000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "808A64E2-BB7D-40AB-A262-C2FAAA2AFD64",
              "rep" : 5,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.40000000000000002,
              "styleId" : "",
              "index" : 0
            }
          ]
        }
      ],
      "_type" : "setsAndReps"
    },
    {
      "id" : "conditioning-15",
      "index" : 15,
      "timeTrial" : null,
      "weekId" : 0,
      "clubId" : "",
      "typeId" : 0,
      "editedAt" : 1615265214,
      "challengeTypeId" : 0,
      "programId" : "",
      "rest" : 180,
      "isLastSession" : true,
      "intervals" : [
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "1CAB99F3-99E6-41ED-B946-1FE20526E080",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "CF944307-F299-4933-962D-E30F535A0513",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "3029F82C-70E3-4386-BE3C-5AA90EDEDAA3",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "A8F6080B-6AC6-4A18-A55E-FE0B37B846E7",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "DBFAE7E5-A1A5-4FF0-AC5C-32A1580F1FC1",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "1C734ECA-E6E3-4D1D-B4BC-1448F831DB43",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "2214D799-00FE-4DEA-8BBA-02A136878BAB",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "2C2B31FE-6F3D-4606-A23D-E7D36BDAE6E4",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "1E6AC9DE-7D79-40EB-AA15-D08319132B56",
          "rep" : 4,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "B916C2B3-6802-4172-B1D8-77E2F8E39185",
          "rep" : 4,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "B02B44E0-8267-4B07-AF1A-8F67DF33778B",
          "rep" : 5,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "D2C556C0-6C8E-4B67-AA30-44F575E01C36",
          "rep" : 5,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "rest",
          "set" : 0,
          "isComplete" : false,
          "id" : "D5CF0CC3-0C62-4C35-92D3-B7F7AE90F26F",
          "rep" : 5,
          "recordedDistance" : 0,
          "seconds" : 240,
          "percentage" : 0,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "E15E9599-F2DE-4CAD-A95C-9B693083C99F",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "6F416464-2BCE-4DDF-BF61-3632BA717B33",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "164244E8-FB76-4DCA-A72F-AEC3C784229A",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "D60EF434-1699-4D44-A5FC-9C6C35FA1DE0",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "64CC0134-AA1D-4658-AC63-0DB1F89E3B7C",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "F9405F20-C414-45F2-AEA6-1A9692A7743A",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "6759E316-7190-40F0-A4BC-813C1C030409",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "393F60DD-0A28-4D1A-898E-C49A418B16CA",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "16798899-BAD0-4B7D-8C2D-2DE55310435D",
          "rep" : 4,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "6DDD9E94-2F40-4BA4-A815-DAF10FD2E915",
          "rep" : 4,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "B5B9A1C6-CAB1-4FAE-BDBA-D683B5C87423",
          "rep" : 5,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "7E44035F-8576-419A-9E6E-7FB3926D592E",
          "rep" : 5,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "rest",
          "set" : 1,
          "isComplete" : false,
          "id" : "C21CBDE1-678B-4C0F-B075-09E909B95AEB",
          "rep" : 5,
          "recordedDistance" : 0,
          "seconds" : 240,
          "percentage" : 0,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 2,
          "isComplete" : false,
          "id" : "93BC2FDD-0874-42C9-96F5-42FFED89E5F7",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 2,
          "isComplete" : false,
          "id" : "CC41470E-3937-4429-8D39-D6EA33343911",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 2,
          "isComplete" : false,
          "id" : "D867790C-4E30-4590-81B8-744FFDF2F4D7",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 2,
          "isComplete" : false,
          "id" : "F797A758-FEEC-4833-A8B5-CB7C598EA481",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 2,
          "isComplete" : false,
          "id" : "21FC2A96-E62A-46AF-BD5E-0B2F89F28162",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 2,
          "isComplete" : false,
          "id" : "87ED95B4-3806-46A8-BB84-491D540FB843",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 2,
          "isComplete" : false,
          "id" : "55321E62-C610-4013-80EB-1C1A30644814",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 2,
          "isComplete" : false,
          "id" : "8AF100F1-94D7-483A-ADFE-11B70A9AD87B",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 2,
          "isComplete" : false,
          "id" : "007DA129-386C-440A-ACE1-B032CE8E5D67",
          "rep" : 4,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 2,
          "isComplete" : false,
          "id" : "B7B69AE7-2E18-41B5-88E1-4E2B5BAC6E3C",
          "rep" : 4,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 2,
          "isComplete" : false,
          "id" : "45842ECC-4C99-41C5-B7C7-52F5C4A80C33",
          "rep" : 5,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 2,
          "isComplete" : false,
          "id" : "55CE3BB3-F954-4F76-B80F-68B01A036962",
          "rep" : 5,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        }
      ],
      "sets" : [
        {
          "id" : "0",
          "reps" : [
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "1CAB99F3-99E6-41ED-B946-1FE20526E080",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "CF944307-F299-4933-962D-E30F535A0513",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "3029F82C-70E3-4386-BE3C-5AA90EDEDAA3",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "A8F6080B-6AC6-4A18-A55E-FE0B37B846E7",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "DBFAE7E5-A1A5-4FF0-AC5C-32A1580F1FC1",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "1C734ECA-E6E3-4D1D-B4BC-1448F831DB43",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "2214D799-00FE-4DEA-8BBA-02A136878BAB",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "2C2B31FE-6F3D-4606-A23D-E7D36BDAE6E4",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "1E6AC9DE-7D79-40EB-AA15-D08319132B56",
              "rep" : 4,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "B916C2B3-6802-4172-B1D8-77E2F8E39185",
              "rep" : 4,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "B02B44E0-8267-4B07-AF1A-8F67DF33778B",
              "rep" : 5,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "D2C556C0-6C8E-4B67-AA30-44F575E01C36",
              "rep" : 5,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "rest",
              "set" : 0,
              "isComplete" : false,
              "id" : "D5CF0CC3-0C62-4C35-92D3-B7F7AE90F26F",
              "rep" : 5,
              "recordedDistance" : 0,
              "seconds" : 240,
              "percentage" : 0,
              "styleId" : "",
              "index" : 0
            }
          ]
        },
        {
          "id" : "1",
          "reps" : [
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "E15E9599-F2DE-4CAD-A95C-9B693083C99F",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "6F416464-2BCE-4DDF-BF61-3632BA717B33",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "164244E8-FB76-4DCA-A72F-AEC3C784229A",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "D60EF434-1699-4D44-A5FC-9C6C35FA1DE0",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "64CC0134-AA1D-4658-AC63-0DB1F89E3B7C",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "F9405F20-C414-45F2-AEA6-1A9692A7743A",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "6759E316-7190-40F0-A4BC-813C1C030409",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "393F60DD-0A28-4D1A-898E-C49A418B16CA",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "16798899-BAD0-4B7D-8C2D-2DE55310435D",
              "rep" : 4,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "6DDD9E94-2F40-4BA4-A815-DAF10FD2E915",
              "rep" : 4,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "B5B9A1C6-CAB1-4FAE-BDBA-D683B5C87423",
              "rep" : 5,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "7E44035F-8576-419A-9E6E-7FB3926D592E",
              "rep" : 5,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "rest",
              "set" : 1,
              "isComplete" : false,
              "id" : "C21CBDE1-678B-4C0F-B075-09E909B95AEB",
              "rep" : 5,
              "recordedDistance" : 0,
              "seconds" : 240,
              "percentage" : 0,
              "styleId" : "",
              "index" : 0
            }
          ]
        },
        {
          "id" : "2",
          "reps" : [
            {
              "typeId" : "work",
              "set" : 2,
              "isComplete" : false,
              "id" : "93BC2FDD-0874-42C9-96F5-42FFED89E5F7",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 2,
              "isComplete" : false,
              "id" : "CC41470E-3937-4429-8D39-D6EA33343911",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 2,
              "isComplete" : false,
              "id" : "D867790C-4E30-4590-81B8-744FFDF2F4D7",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 2,
              "isComplete" : false,
              "id" : "F797A758-FEEC-4833-A8B5-CB7C598EA481",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 2,
              "isComplete" : false,
              "id" : "21FC2A96-E62A-46AF-BD5E-0B2F89F28162",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 2,
              "isComplete" : false,
              "id" : "87ED95B4-3806-46A8-BB84-491D540FB843",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 2,
              "isComplete" : false,
              "id" : "55321E62-C610-4013-80EB-1C1A30644814",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 2,
              "isComplete" : false,
              "id" : "8AF100F1-94D7-483A-ADFE-11B70A9AD87B",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 2,
              "isComplete" : false,
              "id" : "007DA129-386C-440A-ACE1-B032CE8E5D67",
              "rep" : 4,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 2,
              "isComplete" : false,
              "id" : "B7B69AE7-2E18-41B5-88E1-4E2B5BAC6E3C",
              "rep" : 4,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 2,
              "isComplete" : false,
              "id" : "45842ECC-4C99-41C5-B7C7-52F5C4A80C33",
              "rep" : 5,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 2,
              "isComplete" : false,
              "id" : "55CE3BB3-F954-4F76-B80F-68B01A036962",
              "rep" : 5,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            }
          ]
        }
      ],
      "_type" : "setsAndReps"
    },
    {
      "id" : "conditioning-16",
      "index" : 16,
      "timeTrial" : null,
      "weekId" : 0,
      "clubId" : "",
      "typeId" : 0,
      "editedAt" : 1615265214,
      "challengeTypeId" : 0,
      "programId" : "",
      "rest" : 180,
      "isLastSession" : true,
      "intervals" : [
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "E8BE316D-69D6-4249-A77A-92D6B0AB9071",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 120,
          "percentage" : 0.90000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "BF58AA14-740C-4DAC-BD12-A5E47DBBB45E",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.40000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "5CBD6978-DA07-4B6F-8417-D0E660823726",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 120,
          "percentage" : 0.90000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "914699E8-F5D6-4019-AD5C-09D29E0DBF43",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.40000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "DEDBCDB3-93FF-4ADA-9DC1-9FAC7BA85E70",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 120,
          "percentage" : 0.90000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "E6710BCA-B35A-4B1A-B130-D9D864266EDD",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.40000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "44F5A3E6-DC32-4B29-8758-C43458BBFFED",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 120,
          "percentage" : 0.90000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "7A0964A4-EDF3-4859-AF49-3F18A42A5FAB",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.40000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "5600EB9F-35CD-4CB9-9EAF-B729C7DB13A2",
          "rep" : 4,
          "recordedDistance" : 0,
          "seconds" : 120,
          "percentage" : 0.90000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "4E106557-C1A4-4C87-BAB9-E43C3C1F7ED4",
          "rep" : 4,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.40000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "8F232AE2-8554-4F96-B26D-6B61052AC543",
          "rep" : 5,
          "recordedDistance" : 0,
          "seconds" : 120,
          "percentage" : 0.90000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "DF0BCEC5-4402-48CB-AF8B-B1B2C955B24D",
          "rep" : 5,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.40000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "rest",
          "set" : 0,
          "isComplete" : false,
          "id" : "180688B7-A767-4B06-AF11-8554557282E5",
          "rep" : 5,
          "recordedDistance" : 0,
          "seconds" : 240,
          "percentage" : 0,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "EEC1FF5B-A8A7-4406-BED7-E51932D08559",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 120,
          "percentage" : 0.90000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "C66FFCFF-F2C6-4004-9514-B9599132B537",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.40000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "FB2538A9-24C7-408F-9070-9706FA892571",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 120,
          "percentage" : 0.90000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "F6B977CA-C200-42DA-9F63-4F88FC8E28CF",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.40000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "B74570CA-D06F-47F0-AEBB-0DDFBC3A6CD1",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 120,
          "percentage" : 0.90000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "8D37911C-6510-4B39-AAA8-093020A546A0",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.40000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "96F182F1-8EDD-4779-83BE-805A6ED4F52A",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 120,
          "percentage" : 0.90000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "87F151C2-9010-4AC5-B038-F173F772CA8F",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.40000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "2B64B9C7-96BD-4994-86A5-83D52D0BE4D2",
          "rep" : 4,
          "recordedDistance" : 0,
          "seconds" : 120,
          "percentage" : 0.90000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "AB1AFE9D-617A-4E27-B669-151AC4C33FC7",
          "rep" : 4,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.40000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "3B39507E-B135-4917-85F4-634D31A70DDF",
          "rep" : 5,
          "recordedDistance" : 0,
          "seconds" : 120,
          "percentage" : 0.90000000000000002,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "808A64E2-BB7D-40AB-A262-C2FAAA2AFD64",
          "rep" : 5,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.40000000000000002,
          "styleId" : "",
          "index" : 0
        }
      ],
      "sets" : [
        {
          "id" : "0",
          "reps" : [
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "E8BE316D-69D6-4249-A77A-92D6B0AB9071",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 120,
              "percentage" : 0.90000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "BF58AA14-740C-4DAC-BD12-A5E47DBBB45E",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.40000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "5CBD6978-DA07-4B6F-8417-D0E660823726",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 120,
              "percentage" : 0.90000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "914699E8-F5D6-4019-AD5C-09D29E0DBF43",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.40000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "DEDBCDB3-93FF-4ADA-9DC1-9FAC7BA85E70",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 120,
              "percentage" : 0.90000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "E6710BCA-B35A-4B1A-B130-D9D864266EDD",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.40000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "44F5A3E6-DC32-4B29-8758-C43458BBFFED",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 120,
              "percentage" : 0.90000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "7A0964A4-EDF3-4859-AF49-3F18A42A5FAB",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.40000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "5600EB9F-35CD-4CB9-9EAF-B729C7DB13A2",
              "rep" : 4,
              "recordedDistance" : 0,
              "seconds" : 120,
              "percentage" : 0.90000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "4E106557-C1A4-4C87-BAB9-E43C3C1F7ED4",
              "rep" : 4,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.40000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "8F232AE2-8554-4F96-B26D-6B61052AC543",
              "rep" : 5,
              "recordedDistance" : 0,
              "seconds" : 120,
              "percentage" : 0.90000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "DF0BCEC5-4402-48CB-AF8B-B1B2C955B24D",
              "rep" : 5,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.40000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "rest",
              "set" : 0,
              "isComplete" : false,
              "id" : "180688B7-A767-4B06-AF11-8554557282E5",
              "rep" : 5,
              "recordedDistance" : 0,
              "seconds" : 240,
              "percentage" : 0,
              "styleId" : "",
              "index" : 0
            }
          ]
        },
        {
          "id" : "1",
          "reps" : [
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "EEC1FF5B-A8A7-4406-BED7-E51932D08559",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 120,
              "percentage" : 0.90000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "C66FFCFF-F2C6-4004-9514-B9599132B537",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.40000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "FB2538A9-24C7-408F-9070-9706FA892571",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 120,
              "percentage" : 0.90000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "F6B977CA-C200-42DA-9F63-4F88FC8E28CF",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.40000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "B74570CA-D06F-47F0-AEBB-0DDFBC3A6CD1",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 120,
              "percentage" : 0.90000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "8D37911C-6510-4B39-AAA8-093020A546A0",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.40000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "96F182F1-8EDD-4779-83BE-805A6ED4F52A",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 120,
              "percentage" : 0.90000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "87F151C2-9010-4AC5-B038-F173F772CA8F",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.40000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "2B64B9C7-96BD-4994-86A5-83D52D0BE4D2",
              "rep" : 4,
              "recordedDistance" : 0,
              "seconds" : 120,
              "percentage" : 0.90000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "AB1AFE9D-617A-4E27-B669-151AC4C33FC7",
              "rep" : 4,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.40000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "3B39507E-B135-4917-85F4-634D31A70DDF",
              "rep" : 5,
              "recordedDistance" : 0,
              "seconds" : 120,
              "percentage" : 0.90000000000000002,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "808A64E2-BB7D-40AB-A262-C2FAAA2AFD64",
              "rep" : 5,
              "recordedDistance" : 0,
              "seconds" : 60,
              "percentage" : 0.40000000000000002,
              "styleId" : "",
              "index" : 0
            }
          ]
        }
      ],
      "_type" : "setsAndReps"
    },
    {
      "id" : "conditioning-17",
      "index" : 17,
      "timeTrial" : null,
      "weekId" : 0,
      "clubId" : "",
      "typeId" : 0,
      "editedAt" : 1615265214,
      "challengeTypeId" : 0,
      "programId" : "",
      "rest" : 180,
      "isLastSession" : true,
      "intervals" : [
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "1CAB99F3-99E6-41ED-B946-1FE20526E080",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "CF944307-F299-4933-962D-E30F535A0513",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "3029F82C-70E3-4386-BE3C-5AA90EDEDAA3",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "A8F6080B-6AC6-4A18-A55E-FE0B37B846E7",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "DBFAE7E5-A1A5-4FF0-AC5C-32A1580F1FC1",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "1C734ECA-E6E3-4D1D-B4BC-1448F831DB43",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "2214D799-00FE-4DEA-8BBA-02A136878BAB",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "2C2B31FE-6F3D-4606-A23D-E7D36BDAE6E4",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "1E6AC9DE-7D79-40EB-AA15-D08319132B56",
          "rep" : 4,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "B916C2B3-6802-4172-B1D8-77E2F8E39185",
          "rep" : 4,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "B02B44E0-8267-4B07-AF1A-8F67DF33778B",
          "rep" : 5,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "D2C556C0-6C8E-4B67-AA30-44F575E01C36",
          "rep" : 5,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "rest",
          "set" : 0,
          "isComplete" : false,
          "id" : "D5CF0CC3-0C62-4C35-92D3-B7F7AE90F26F",
          "rep" : 5,
          "recordedDistance" : 0,
          "seconds" : 240,
          "percentage" : 0,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "E15E9599-F2DE-4CAD-A95C-9B693083C99F",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "6F416464-2BCE-4DDF-BF61-3632BA717B33",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "164244E8-FB76-4DCA-A72F-AEC3C784229A",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "D60EF434-1699-4D44-A5FC-9C6C35FA1DE0",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "64CC0134-AA1D-4658-AC63-0DB1F89E3B7C",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "F9405F20-C414-45F2-AEA6-1A9692A7743A",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "6759E316-7190-40F0-A4BC-813C1C030409",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "393F60DD-0A28-4D1A-898E-C49A418B16CA",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "16798899-BAD0-4B7D-8C2D-2DE55310435D",
          "rep" : 4,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "6DDD9E94-2F40-4BA4-A815-DAF10FD2E915",
          "rep" : 4,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "B5B9A1C6-CAB1-4FAE-BDBA-D683B5C87423",
          "rep" : 5,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "7E44035F-8576-419A-9E6E-7FB3926D592E",
          "rep" : 5,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "rest",
          "set" : 1,
          "isComplete" : false,
          "id" : "C21CBDE1-678B-4C0F-B075-09E909B95AEB",
          "rep" : 5,
          "recordedDistance" : 0,
          "seconds" : 240,
          "percentage" : 0,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 2,
          "isComplete" : false,
          "id" : "93BC2FDD-0874-42C9-96F5-42FFED89E5F7",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 2,
          "isComplete" : false,
          "id" : "CC41470E-3937-4429-8D39-D6EA33343911",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 2,
          "isComplete" : false,
          "id" : "D867790C-4E30-4590-81B8-744FFDF2F4D7",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 2,
          "isComplete" : false,
          "id" : "F797A758-FEEC-4833-A8B5-CB7C598EA481",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 2,
          "isComplete" : false,
          "id" : "21FC2A96-E62A-46AF-BD5E-0B2F89F28162",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 2,
          "isComplete" : false,
          "id" : "87ED95B4-3806-46A8-BB84-491D540FB843",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 2,
          "isComplete" : false,
          "id" : "55321E62-C610-4013-80EB-1C1A30644814",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 2,
          "isComplete" : false,
          "id" : "8AF100F1-94D7-483A-ADFE-11B70A9AD87B",
          "rep" : 3,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 2,
          "isComplete" : false,
          "id" : "007DA129-386C-440A-ACE1-B032CE8E5D67",
          "rep" : 4,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 2,
          "isComplete" : false,
          "id" : "B7B69AE7-2E18-41B5-88E1-4E2B5BAC6E3C",
          "rep" : 4,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 2,
          "isComplete" : false,
          "id" : "45842ECC-4C99-41C5-B7C7-52F5C4A80C33",
          "rep" : 5,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 1,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 2,
          "isComplete" : false,
          "id" : "55CE3BB3-F954-4F76-B80F-68B01A036962",
          "rep" : 5,
          "recordedDistance" : 0,
          "seconds" : 30,
          "percentage" : 0.59999999999999998,
          "styleId" : "",
          "index" : 0
        }
      ],
      "sets" : [
        {
          "id" : "0",
          "reps" : [
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "1CAB99F3-99E6-41ED-B946-1FE20526E080",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "CF944307-F299-4933-962D-E30F535A0513",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "3029F82C-70E3-4386-BE3C-5AA90EDEDAA3",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "A8F6080B-6AC6-4A18-A55E-FE0B37B846E7",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "DBFAE7E5-A1A5-4FF0-AC5C-32A1580F1FC1",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "1C734ECA-E6E3-4D1D-B4BC-1448F831DB43",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "2214D799-00FE-4DEA-8BBA-02A136878BAB",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "2C2B31FE-6F3D-4606-A23D-E7D36BDAE6E4",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "1E6AC9DE-7D79-40EB-AA15-D08319132B56",
              "rep" : 4,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "B916C2B3-6802-4172-B1D8-77E2F8E39185",
              "rep" : 4,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 0,
              "isComplete" : false,
              "id" : "B02B44E0-8267-4B07-AF1A-8F67DF33778B",
              "rep" : 5,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 0,
              "isComplete" : false,
              "id" : "D2C556C0-6C8E-4B67-AA30-44F575E01C36",
              "rep" : 5,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "rest",
              "set" : 0,
              "isComplete" : false,
              "id" : "D5CF0CC3-0C62-4C35-92D3-B7F7AE90F26F",
              "rep" : 5,
              "recordedDistance" : 0,
              "seconds" : 240,
              "percentage" : 0,
              "styleId" : "",
              "index" : 0
            }
          ]
        },
        {
          "id" : "1",
          "reps" : [
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "E15E9599-F2DE-4CAD-A95C-9B693083C99F",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "6F416464-2BCE-4DDF-BF61-3632BA717B33",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "164244E8-FB76-4DCA-A72F-AEC3C784229A",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "D60EF434-1699-4D44-A5FC-9C6C35FA1DE0",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "64CC0134-AA1D-4658-AC63-0DB1F89E3B7C",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "F9405F20-C414-45F2-AEA6-1A9692A7743A",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "6759E316-7190-40F0-A4BC-813C1C030409",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "393F60DD-0A28-4D1A-898E-C49A418B16CA",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "16798899-BAD0-4B7D-8C2D-2DE55310435D",
              "rep" : 4,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "6DDD9E94-2F40-4BA4-A815-DAF10FD2E915",
              "rep" : 4,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 1,
              "isComplete" : false,
              "id" : "B5B9A1C6-CAB1-4FAE-BDBA-D683B5C87423",
              "rep" : 5,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 1,
              "isComplete" : false,
              "id" : "7E44035F-8576-419A-9E6E-7FB3926D592E",
              "rep" : 5,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "rest",
              "set" : 1,
              "isComplete" : false,
              "id" : "C21CBDE1-678B-4C0F-B075-09E909B95AEB",
              "rep" : 5,
              "recordedDistance" : 0,
              "seconds" : 240,
              "percentage" : 0,
              "styleId" : "",
              "index" : 0
            }
          ]
        },
        {
          "id" : "2",
          "reps" : [
            {
              "typeId" : "work",
              "set" : 2,
              "isComplete" : false,
              "id" : "93BC2FDD-0874-42C9-96F5-42FFED89E5F7",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 2,
              "isComplete" : false,
              "id" : "CC41470E-3937-4429-8D39-D6EA33343911",
              "rep" : 0,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 2,
              "isComplete" : false,
              "id" : "D867790C-4E30-4590-81B8-744FFDF2F4D7",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 2,
              "isComplete" : false,
              "id" : "F797A758-FEEC-4833-A8B5-CB7C598EA481",
              "rep" : 1,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 2,
              "isComplete" : false,
              "id" : "21FC2A96-E62A-46AF-BD5E-0B2F89F28162",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 2,
              "isComplete" : false,
              "id" : "87ED95B4-3806-46A8-BB84-491D540FB843",
              "rep" : 2,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 2,
              "isComplete" : false,
              "id" : "55321E62-C610-4013-80EB-1C1A30644814",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 2,
              "isComplete" : false,
              "id" : "8AF100F1-94D7-483A-ADFE-11B70A9AD87B",
              "rep" : 3,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 2,
              "isComplete" : false,
              "id" : "007DA129-386C-440A-ACE1-B032CE8E5D67",
              "rep" : 4,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 2,
              "isComplete" : false,
              "id" : "B7B69AE7-2E18-41B5-88E1-4E2B5BAC6E3C",
              "rep" : 4,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "work",
              "set" : 2,
              "isComplete" : false,
              "id" : "45842ECC-4C99-41C5-B7C7-52F5C4A80C33",
              "rep" : 5,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 1,
              "styleId" : "",
              "index" : 0
            },
            {
              "typeId" : "recovery",
              "set" : 2,
              "isComplete" : false,
              "id" : "55CE3BB3-F954-4F76-B80F-68B01A036962",
              "rep" : 5,
              "recordedDistance" : 0,
              "seconds" : 30,
              "percentage" : 0.59999999999999998,
              "styleId" : "",
              "index" : 0
            }
          ]
        }
      ],
      "_type" : "setsAndReps"
    }
  ]
}


export default SessionList;