import React from 'react';
import moment from 'moment';

const getName = athlete => {
  if (athlete.name) {
    return athlete.name
  }
  return `${athlete.firstName} ${athlete.lastName}`
}

const getMas = athlete => {
  const { time, distance, mas } = athlete;
  if (mas) {
    return parseFloat(mas).toFixed(2)
  }

  if (time && distance) {
    const ms = distance / time;
    return parseFloat(ms).toFixed(2);
  }

  return 0.00
}

const getDate = athlete => {
  if (athlete.lastTested) {
    return moment.unix(athlete.lastTested).format('ddd D MMM')
  }
}

const SelectAthleteRow = ({ idx, isSelected, onClick, athlete }) => {
  const isEven = idx % 2;
  const bgClass = isEven ? 'bg-white' : 'bg-gray-50';
  const toggleClass = isSelected ? 'bg-blue-600' : 'bg-gray-200';
  const slideClass = isSelected ? "translate-x-5" : "translate-x-0";
  
  return (
    <tr className={bgClass}>
      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
        {getName(athlete)}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {`${getMas(athlete)} m/s`}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {getDate(athlete)}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
              {/* <!-- This example requires Tailwind CSS v2.0+ -->
        <!-- Enabled: "bg-blue-600", Not Enabled: "bg-gray-200" --> */}
        <button onClick={event => onClick(event, athlete) } type="button" className={`${toggleClass} relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`} aria-pressed="false">
          <span className="sr-only">Selected</span>
          <span aria-hidden="true" className={`${slideClass} pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`}></span>
        </button>
      </td>
    </tr>
  )
}

export default SelectAthleteRow