import React, { Component } from 'react';
import {
  useParams
} from "react-router-dom";

import Nav from '../../components/Nav';
import ProgramList from '../../components/ProgramList';
import AthleteList from './AthleteList';
import ClubDetailsHeader from './ClubDetailsHeader';
import ClubTabBar from './ClubTabBar';



class ClubDetailsPage extends Component {

  state = {
    selectedId: 'leaderboard'
  }

  didSelectTab = selectedId => {
    console.log('did select id', selectedId);
    this.setState({ selectedId });
  }

  render() {
    const { id } = this.props.match.params
    console.log(`club id: ${id}`);
    const { location } = this.props;
    console.log(this.props);
    const club = location && location.state && location.state.data ? location.state.data : {};
    console.log(club);

    return (
      <React.Fragment>
        <Nav />
        <ClubDetailsHeader club={club} />
        <div className="my-20 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-3xl mx-auto">
            <ClubTabBar onSelect={this.didSelectTab} selectedId={this.state.selectedId} />
            {
              this.getTabContent(id)
            }
          </div>
        </div>
        
      </React.Fragment>
    )
  }


  getTabContent = clubId => {
    switch (this.state.selectedId) {
      case 'leaderboard':
        return <AthleteList clubId={clubId} /> 
        case 'programs':
          return <ProgramList />
      default:
        return <div>{this.state.selectedId}</div>     
    }
  }

  
}





export default ClubDetailsPage;