import React, { useEffect } from 'react';
import { db } from '../../services/WebService';

import masLogo from '../../assets/mas-logo.svg'

export const useClubs = userId => {
  // initialize our default state
  const [error, setError] = React.useState(false) 
  const [loading, setLoading] = React.useState(true) 
  const [clubs, setClub] = React.useState(null);

  // when the id attribute changes (including mount)
  // subscribe to the recipe document and update
  // our state when it changes.
  useEffect(
    () => {
      db.collection('user-clubs').doc(userId).collection('clubs').get().then(snapshot => { 
        
        setLoading(false) 
        
        var data = [];

        snapshot.forEach(doc => {
          data.push(doc.data());
        });
        
        setClub(data);

      }, err => { setError(err) } )
      
    },
    [userId]
  )

  return {
    error,
    loading,
    clubs,
  }
}


export const usePrograms = programIds => {
  // initialize our default state
  const [error, setError] = React.useState(false) 
  const [loading, setLoading] = React.useState(true) 
  const [programs, setPrograms] = React.useState(defaultPrograms);
  
  // when the id attribute changes (including mount)
  // subscribe to the recipe document and update
  // our state when it changes.
  useEffect(
    () => {
      if (programIds && programIds.length && programIds.length > 0) {
        console.log('query user programs');

        db.collection('programs').where('id', 'in', programIds).get().then(snapshot => { 
          
          setLoading(false) 
          
          var data = defaultPrograms;
          
          console.log(snapshot);
          console.log('user programs');

          snapshot.forEach(doc => {
            console.log(doc.data());
            data.push(doc.data());
          });
          
          setPrograms(data);
  
        }, err => { setError(err) } )
      } else {
        console.log('no program ids');
        setLoading(false) 
      }
      
      
    },
    [defaultPrograms]
  )

  return {
    error,
    loading,
    programs,
  }
}



const defaultPrograms = [
  {
    id: '4D10C944-E4B1-4778-B334-47E8875A32CA',
    name: 'Base Conditioning',
    details: 'Beginner • 4 - 6 Weeks',
    coverImgUrl: 'https://images.unsplash.com/photo-1552674605-db6ffd4facb5?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1200&q=80',
    owner: {
      name: 'Sean O\'Connor',
      company: 'Full Motion',
      logoUrl: masLogo
    }
  },
  {
    id: '4D10C944-E4B1-4778-B334-47E8875A32CA',
    name: 'Half Marathon',
    details: 'Intermediate • 16 Weeks',
    coverImgUrl: 'https://images.unsplash.com/flagged/photo-1556746834-cbb4a38ee593?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1200&q=80',
    owner: {
      name: 'Sean O\'Connor',
      company: 'Full Motion',
      logoUrl: masLogo
    }
  },
  {
    id: '4D10C944-E4B1-4778-B334-47E8875A32CA',
    name: 'Park Run',
    details: 'Intermediate • 4 - 6 Weeks',
    coverImgUrl: 'https://images.unsplash.com/photo-1590646299178-1b26ab821e34?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1400&q=80',
    owner: {
      name: 'Sean O\'Connor',
      company: 'Full Motion',
      logoUrl: masLogo
    }
  },
]