import React from 'react';



const TabItems = [
  {
    id: 'leaderboard',
    name: 'Leaderboard'
  },
  {
    id: 'programs',
    name: 'Programs'
  },
  {
    id: 'members',
    name: 'Members'
  },
  {
    id: 'details',
    name: 'Details'
  },
  {
    id: 'admins',
    name: 'Admin'
  },
]



const ClubTabBar = ({ onSelect, selectedId }) => {
  return (
    <div className="mb-12" >
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">Select a tab</label>
        <select defaultValue={selectedId} id="tabs" name="tabs" className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md">
          <option >Leaderboard</option>
          <option>Programs</option>
          <option>Members</option>
          <option>Settings</option>
          <option>Admin</option>
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {
              TabItems.map((item, index) => <TabbarItem key={index} onSelect={onSelect} item={item} selectedId={selectedId} />)
            }
          </nav>
        </div>
      </div>
    </div>
  )
}

const TabbarItem = ({ item, onSelect, selectedId }) => {

  const isSelected = item.id === selectedId;
  const classes = isSelected ? 'border-blue-500 text-blue-600 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'

  return (
    <div onClick={() => onSelect(item.id)} className={classes}>
      {item.name}
    </div>
  )
}


export default ClubTabBar;