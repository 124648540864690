import React from 'react';

import masLogo from '../../assets/mas-logo.svg'


const ProgramDetailsHeader = ({ program, onJoin, isJoined }) => {
  const coverImgUrl = program && program.coverImgUrl ? program.coverImgUrl : '';
  const logoUrl = program && program.logoUrl ? program.logoUrl : '';
  const name = program && program.name ? program.name : '';
  const details = program && program.details ? program.details : '';

  const btnClass = isJoined ? 'bg-blue-600 text-gray-50 hover:bg-blue-700' : 'border border-blue-600 text-blue-600 hover:bg-blue-700 hover:text-gray-50';

  return (
    <div>
      <div>
        <img className="h-72 w-full object-cover lg:h-72" src={coverImgUrl} alt="" />
      </div>
      <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
          <div className="flex">
            <img className="h-24 w-24 rounded-full ring-4 ring-white sm:h-32 sm:w-32" src={logoUrl || masLogo} alt="" />
          </div>
          <div className="mt-6 sm:flex-1 sm:min-w-0 sm:flex sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
            <div className="sm:hidden md:block mt-6 min-w-0 flex-1">
              <h1 className="text-2xl font-bold text-gray-900 truncate">
                {name}
              </h1>
            </div>
            <div className="mt-6 flex flex-col justify-stretch space-y-3 sm:flex-row sm:space-y-0 sm:space-x-4">
            
              <button type="button" onClick={() => onJoin(isJoined)} className={`${btnClass} inline-flex justify-center px-4 py-2 shadow-sm text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`} >
                {/* <!-- Heroicon name: solid/phone --> */}
                {/* <svg className="-ml-1 mr-2 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                </svg> */}
                <span>{ isJoined ? 'Joined' : 'Join'}</span>
              </button>
            </div>
            
          </div>
        </div>
        <div className="hidden sm:block md:hidden mt-6 min-w-0 flex-1">
          <h1 className="text-2xl font-bold text-gray-900 truncate">
            {name || ''}
          </h1>
        </div>
        <p className="pt-10" >{details || ''}</p>
      </div>
      
    </div>
  )
}

export default ProgramDetailsHeader;