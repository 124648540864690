import React from 'react';
import Nav from '../../components/Nav';

import SessionCard from '../../components/SessionCard';
import Breadcrumbs from '../../components/Breadcrumbs';

const crumbs = [
  {
    title: 'Base Conditioning',
    path: '/program/base-conditioning'
  },
  {
    title: 'Session 1',
    path: '/session/base-conditioning-1'
  }
]

const ProgramPage = () => {
  return (
    <React.Fragment>
      <div className="fixed top-0 left-0 w-1/2 h-full bg-gray-50" aria-hidden="true"></div>
        <div className="fixed top-0 right-0 w-1/2 h-full bg-gray-50" aria-hidden="true"></div>
        <div className="relative min-h-screen flex flex-col">
          {/* <!-- Navbar --> */}
          <Nav />
          <div className="mt-5 mb-1 flex-grow w-full max-w-7xl mx-auto xl:px-8 lg:flex">
            <Breadcrumbs items={crumbs}  />
          </div>
          
          {/* <!-- 3 column wrapper --> */}
          <div className="flex-grow w-full max-w-7xl mx-auto xl:px-8 lg:flex">
          
            {/* <!-- Left sidebar & main wrapper --> */}
            <div className="flex-1 min-w-0 bg-gray-50 xl:flex">
              <div className=" xl:flex-shrink-0 xl:w-64 bg-gray-50">
                <div className="h-full pl-4 pr-6 py-6 sm:pl-6 lg:pl-8 xl:pl-0">
                  {/* <!-- Start left column area --> */}
                  <div className="h-full relative" style={{ minHeight: '12rem' }}>
                    {/* <div className="absolute inset-0 border-2 border-gray-200 border-dashed rounded-lg"></div> */}
                    
                    <h3>Session Details</h3>
                  </div>
                  {/* <!-- End left column area --> */}
                </div>
              </div>

              <div className="lg:min-w-2 lg:flex-1">
                <div className="h-full py-6 px-4 sm:px-6 lg:px-8">
                  {/* <!-- Start main area--> */}
                  <div className="relative h-full" style={{ minHeight: '36rem' }}>
                    <SessionCard idx={0} session={session} />
                  </div>
                  {/* <!-- End main area --> */}
                </div>
              </div>
            </div>

            <div className="bg-gray-50 pr-4 sm:pr-6 lg:pr-4 lg:flex-shrink-0 xl:pr-0">
              <div className="h-full pl-6 py-6 lg:w-80">
                {/* <!-- Start right column area --> */}
                <div className="h-full relative" style={{ minHeight: '16rem' }}>
                  <div className="absolute inset-0 border-2 border-gray-200 border-dashed rounded-lg"></div>
                  {/* <Programs /> */}
                </div>
                {/* <!-- End right column area --> */}
              </div>
            </div>
          </div>
        </div>
    </React.Fragment>
  )
};


const session = {
  "id" : "conditioning-0",
  "index" : 0,
  "timeTrial" : null,
  "weekId" : 0,
  "clubId" : "",
  "typeId" : 0,
  "editedAt" : 1615265214,
  "challengeTypeId" : 0,
  "programId" : "",
  "rest" : 240,
  "isLastSession" : true,
  "intervals" : [
    {
      "typeId" : "work",
      "set" : 0,
      "isComplete" : false,
      "id" : "4E34E801-606E-45DD-9DD3-7AE11E1570B0",
      "rep" : 0,
      "recordedDistance" : 0,
      "seconds" : 180,
      "percentage" : 0.84999999999999998,
      "styleId" : "",
      "index" : 0
    },
    {
      "typeId" : "recovery",
      "set" : 0,
      "isComplete" : false,
      "id" : "5F493A9F-A9CB-4A97-89A7-E329046E5504",
      "rep" : 0,
      "recordedDistance" : 0,
      "seconds" : 60,
      "percentage" : 0.29999999999999999,
      "styleId" : "",
      "index" : 0
    },
    {
      "typeId" : "work",
      "set" : 0,
      "isComplete" : false,
      "id" : "81965A21-6B75-4FD5-94C9-6B8EAB5015DD",
      "rep" : 1,
      "recordedDistance" : 0,
      "seconds" : 180,
      "percentage" : 0.84999999999999998,
      "styleId" : "",
      "index" : 0
    },
    {
      "typeId" : "recovery",
      "set" : 0,
      "isComplete" : false,
      "id" : "F23F10F8-D5C9-4C8A-AB56-192A4AF093D6",
      "rep" : 1,
      "recordedDistance" : 0,
      "seconds" : 60,
      "percentage" : 0.29999999999999999,
      "styleId" : "",
      "index" : 0
    },
    {
      "typeId" : "work",
      "set" : 0,
      "isComplete" : false,
      "id" : "47DE0666-0CEE-4FF5-AC2B-78895C424288",
      "rep" : 2,
      "recordedDistance" : 0,
      "seconds" : 180,
      "percentage" : 0.84999999999999998,
      "styleId" : "",
      "index" : 0
    },
    {
      "typeId" : "recovery",
      "set" : 0,
      "isComplete" : false,
      "id" : "07A4D5DB-9D30-4990-B519-01EDCE614BAD",
      "rep" : 2,
      "recordedDistance" : 0,
      "seconds" : 60,
      "percentage" : 0.29999999999999999,
      "styleId" : "",
      "index" : 0
    },
    {
      "typeId" : "rest",
      "set" : 0,
      "isComplete" : false,
      "id" : "91FBAE5B-356C-4D02-B602-ADC4E5EB73B9",
      "rep" : 2,
      "recordedDistance" : 0,
      "seconds" : 240,
      "percentage" : 0,
      "styleId" : "",
      "index" : 0
    },
    {
      "typeId" : "work",
      "set" : 1,
      "isComplete" : false,
      "id" : "33EFAF8D-6D0F-4E85-9A12-734DD942B608",
      "rep" : 0,
      "recordedDistance" : 0,
      "seconds" : 180,
      "percentage" : 0.84999999999999998,
      "styleId" : "",
      "index" : 0
    },
    {
      "typeId" : "recovery",
      "set" : 1,
      "isComplete" : false,
      "id" : "9F84BBBA-BFAD-403D-9223-404C9F3BC4FA",
      "rep" : 0,
      "recordedDistance" : 0,
      "seconds" : 60,
      "percentage" : 0.29999999999999999,
      "styleId" : "",
      "index" : 0
    },
    {
      "typeId" : "work",
      "set" : 1,
      "isComplete" : false,
      "id" : "5211E880-6ED3-45A6-AF7A-2771560028FF",
      "rep" : 1,
      "recordedDistance" : 0,
      "seconds" : 180,
      "percentage" : 0.84999999999999998,
      "styleId" : "",
      "index" : 0
    },
    {
      "typeId" : "recovery",
      "set" : 1,
      "isComplete" : false,
      "id" : "82A615A4-BC5E-4828-A88F-EAC304046746",
      "rep" : 1,
      "recordedDistance" : 0,
      "seconds" : 60,
      "percentage" : 0.29999999999999999,
      "styleId" : "",
      "index" : 0
    },
    {
      "typeId" : "work",
      "set" : 1,
      "isComplete" : false,
      "id" : "3DE11207-2E29-4F1A-936F-37032A4B37B4",
      "rep" : 2,
      "recordedDistance" : 0,
      "seconds" : 180,
      "percentage" : 0.84999999999999998,
      "styleId" : "",
      "index" : 0
    },
    {
      "typeId" : "recovery",
      "set" : 1,
      "isComplete" : false,
      "id" : "A5B452BC-D804-44CB-AA3B-4DC873518032",
      "rep" : 2,
      "recordedDistance" : 0,
      "seconds" : 60,
      "percentage" : 0.29999999999999999,
      "styleId" : "",
      "index" : 0
    }
  ],
  "sets" : [
    {
      "id" : "0",
      "reps" : [
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "4E34E801-606E-45DD-9DD3-7AE11E1570B0",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 180,
          "percentage" : 0.84999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "5F493A9F-A9CB-4A97-89A7-E329046E5504",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.29999999999999999,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "81965A21-6B75-4FD5-94C9-6B8EAB5015DD",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 180,
          "percentage" : 0.84999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "F23F10F8-D5C9-4C8A-AB56-192A4AF093D6",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.29999999999999999,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 0,
          "isComplete" : false,
          "id" : "47DE0666-0CEE-4FF5-AC2B-78895C424288",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 180,
          "percentage" : 0.84999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 0,
          "isComplete" : false,
          "id" : "07A4D5DB-9D30-4990-B519-01EDCE614BAD",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.29999999999999999,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "rest",
          "set" : 0,
          "isComplete" : false,
          "id" : "91FBAE5B-356C-4D02-B602-ADC4E5EB73B9",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 240,
          "percentage" : 0,
          "styleId" : "",
          "index" : 0
        }
      ]
    },
    {
      "id" : "1",
      "reps" : [
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "33EFAF8D-6D0F-4E85-9A12-734DD942B608",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 180,
          "percentage" : 0.84999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "9F84BBBA-BFAD-403D-9223-404C9F3BC4FA",
          "rep" : 0,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.29999999999999999,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "5211E880-6ED3-45A6-AF7A-2771560028FF",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 180,
          "percentage" : 0.84999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "82A615A4-BC5E-4828-A88F-EAC304046746",
          "rep" : 1,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.29999999999999999,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "work",
          "set" : 1,
          "isComplete" : false,
          "id" : "3DE11207-2E29-4F1A-936F-37032A4B37B4",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 180,
          "percentage" : 0.84999999999999998,
          "styleId" : "",
          "index" : 0
        },
        {
          "typeId" : "recovery",
          "set" : 1,
          "isComplete" : false,
          "id" : "A5B452BC-D804-44CB-AA3B-4DC873518032",
          "rep" : 2,
          "recordedDistance" : 0,
          "seconds" : 60,
          "percentage" : 0.29999999999999999,
          "styleId" : "",
          "index" : 0
        }
      ]
    }
  ],
  "_type" : "setsAndReps"
}

export default ProgramPage;