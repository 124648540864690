import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import PrivateRoute from './routes/PrivateRoute';

import AddAthlete from './Pages/addAthlete/AddAthlete.js';
import Login from './Pages/auth/Login.js';
import BeepTestContainer from './Pages/club/BeepTest/BeepTestContainer.js';
import BroncoTestContainer from './Pages/club/BroncoTest/BroncoTestContainer.js';
import ClubDetailsPage from './Pages/club/ClubDetailsPage.js';
import SelectAthletesContainer from './Pages/club/selectAthletes/SelectAthletesContainer.js';
import Dashboard from './Pages/dashboard/Dashboard.js';
import Home from './Pages/Home.js';
import ProgramDetails from './Pages/programs/ProgramDetails.js';
import SessionDetailsPage from './Pages/sessions/SessionDetailsPage.js';
import Terms from './Pages/Terms.js';




function App() {

  return (
    <Router >
      <Switch>
        <Route exact path="/" component={Home} exact />
        <Route path="/terms" component={Terms} />
        <Route path="/login" component={Login} />
        
        <PrivateRoute path="/dashboard" component={Dashboard} />
        <PrivateRoute path="/program/:id" component={ProgramDetails} />
        <PrivateRoute path="/session/:id" component={SessionDetailsPage} />
        <PrivateRoute path="/club/:id" component={ClubDetailsPage} />
        <PrivateRoute path="/add-athlete" component={AddAthlete} />
        <PrivateRoute path="/test/athletes" component={SelectAthletesContainer} />
        <PrivateRoute path="/beep-test" component={BeepTestContainer} />
        <PrivateRoute path="/bronco-test" component={BroncoTestContainer} />
      </Switch>
    </Router>
  );
}

export default App;
