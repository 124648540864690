import { useState } from 'react'
import { Transition } from '@headlessui/react'
import masLogo from '../assets/mas-logo.svg'
import { Link } from 'react-router-dom'
import masImg1 from '../assets/mas-runner-1.png'
import masImg2 from '../assets/mas-runner-2.png'
import masImg3 from '../assets/mas-runner-3.png'
import mrHero from '../assets/mr-hero.png'

function Home() {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className="App">
      <div className="">
        <div className="relative overflow-hidden">
          <header className="relative">
            <div className="bg-gray-900 pt-6">
              <nav className="relative max-w-7xl mx-auto flex items-center justify-between px-4 sm:px-6" aria-label="Global">
                <div className="flex items-center flex-1">
                  <div className="flex items-center justify-between w-full md:w-auto">
                    <a href="#">
                      <span className="sr-only">Workflow</span>
                      <img className="h-8 w-auto sm:h-10" src={masLogo} alt=""/>
                    </a>
                    <div className="-mr-2 flex items-center md:hidden">
                      <button type="button" onClick={() => setIsOpen(!isOpen)} className="bg-gray-900 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-800 focus:outline-none focus:ring-2 focus-ring-inset focus:ring-white" id="main-menu" aria-haspopup="true">
                        <span className="sr-only">Open main menu</span>
                        {/* <!-- Heroicon name: outline/menu --> */}
                        <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="hidden md:flex md:items-center md:space-x-6">
                  <a href="/" className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700">
                    Download the app
                  </a>
                  <Link to="/login" >
                    <div className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700">
                      Login
                    </div>
                  </Link>
                </div>
              </nav>
            </div>

            <Transition
              show={isOpen}
              enter="duration-200 ease-out"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="duration-100 ease-in"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              {(ref) => (
                <div ref={ref}>
                  <div className="absolute top-0 inset-x-0 p-2 transition transform origin-top md:hidden">
                    <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
                      <div className="px-5 pt-4 flex items-center justify-between">
                        <div>
                          <img className="h-8 w-auto" src={masLogo} alt=""/>
                        </div>
                        <div className="-mr-2">
                          <button type="button" onClick={() => setIsOpen(!isOpen)} className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-600">
                            <span className="sr-only">Close menu</span>
                            {/* <!-- Heroicon name: outline/x --> */}
                            <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                          </button>
                        </div>
                      </div>
                      <div className="pt-5 pb-6" role="menu" aria-orientation="vertical" aria-labelledby="main-menu">
                        <div role="none" className="mt-6 px-5">
                          <a href="#" className="block text-center w-full py-3 px-4 rounded-md shadow border border-indigo-700 text-indigo-600 font-medium hover:bg-indigo-700">Download the app</a>
                        </div>
                        <div role="none" className="mt-6 px-5">
                          <Link to="login" >
                            <div className="block text-center w-full py-3 px-4 rounded-md shadow bg-indigo-600 text-white font-medium hover:bg-indigo-700">Login</div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Transition>
          </header>

          <main>
            <div className="pt-10 bg-gray-900 sm:pt-16 lg:pt-8 lg:pb-14 lg:overflow-hidden">
              <div className="mx-auto max-w-7xl lg:px-8">
                <div className="lg:grid lg:grid-cols-2 lg:gap-8">
                  <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center">
                    <div className="lg:py-24">
                      <h1 className="mt-4 text-4xl tracking-tight font-extrabold text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                        <span className="">Train for </span>
                        <span className="text-indigo-400">more</span>
                      </h1>
                      <p className="mt-3 text-base text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                        Guided programs to improve your MAS and help you run faster and further.
                      </p>
                      <div className="mt-10 max-w-sm mx-auto sm:max-w-none sm:inline-block">
                          <div className="space-y-4 sm:space-y-0 sm:mx-auto">
                            <a href="#" className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-indigo-700 bg-white hover:bg-indigo-50 sm:px-8">
                              Download the app
                            </a>
                          </div>
                          <div className="space-y-4 sm:space-y-0 sm:mx-auto">
                            <Link to="login">
                              <div className="mt-4 flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-700 hover:bg-indigo-50 sm:px-8">
                                Login
                              </div>
                            </Link>                            
                          </div>
                        </div>
                    </div>
                  </div>
                  <div className="mt-12 -mb-16 sm:-mb-48 lg:m-0 lg:relative">
                    <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0">
                      <img className="w-full lg:inset-y-0 lg:w-2/3 lg:max-w-full lg:mx-auto" src={mrHero} alt=""/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            

            {/* <!-- More main page content here... --> */}
          </main>
        </div>
      </div>


      {/* <!-- This example requires Tailwind CSS v2.0+ --> */}
      <div className="relative bg-white pt-16 pb-32 overflow-hidden">
        <div className="relative">
          <div className="lg:mx-auto lg:max-w-5xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
          < div className="mt-12 sm:mt-16 lg:mt-0 max-w-xl mx-auto px-4 sm:px-6 lg:mx-0">
              <div className="lg:h-full max-w-full">
                <img className="w-full lg:h-full" src={masImg1} alt="Inbox user interface"/>
              </div>
            </div>
            <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
              <div>
                <div className="mt-6">
                  <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                  A program for every goal
                  </h2>
                  <p className="mt-4 text-lg text-gray-500">
                  Each program is structured to provide training sessions that progressively improve your fitness.
                  </p>
                  {/* <div className="mt-6">
                    <a href="#" className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700">
                      Get started
                    </a>
                  </div> */}
                </div>
              </div>
              <div className="mt-8 border-t border-gray-200 pt-6">
                <blockquote>
                  <div>
                    <p className="text-base text-gray-500">
                      &ldquo;Cras velit quis eros eget rhoncus lacus ultrices sed diam. Sit orci risus aenean curabitur donec aliquet. Mi venenatis in euismod ut.&rdquo;
                    </p>
                  </div>
                  <footer className="mt-3">
                    <div className="flex items-center space-x-3">
                      <div className="flex-shrink-0">
                        <img className="h-6 w-6 rounded-full" src="https://images.unsplash.com/photo-1509783236416-c9ad59bae472?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80" alt=""/>
                      </div>
                      <div className="text-base font-medium text-gray-700">
                        Marcia Hill, Digital Marketing Manager
                      </div>
                    </div>
                  </footer>
                </blockquote>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-24 mb-24">
          <div className="flex flex-col-reverse lg:mx-auto lg:max-w-5xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
            <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0">
              <div>
                <div className="mt-6">
                  <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                  Learn to pace yourself
                  </h2>
                  <p className="mt-4 text-lg text-gray-500">
                  Every run can be guided through your headphones. Listen to your running playlist while we tell you when to run and when to rest to optimise your session.                  </p>
                  {/* <div className="mt-6">
                    <a href="#" className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700">
                      Get started
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="mt-12 sm:mt-16 lg:mt-0 max-w-xl mx-auto px-4 sm:px-6 lg:mx-0">
              <div className="lg:h-full max-w-full">
                <img className="w-full lg:h-full" src={masImg2} alt="Customer profile user interface"/>
              </div>
            </div>
          </div>
        </div>
        <div className="relative">
          <div className="lg:mx-auto lg:max-w-5xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24 lg:items-center">
            <div className="mt-12 sm:mt-16 lg:mt-0 max-w-xl mx-auto px-4 sm:px-6 lg:mx-0">
              <div className="lg:h-full max-w-full">
                <img className="w-full lg:h-full" src={masImg3} alt="Inbox user interface"/>
              </div>
            </div>
            <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
              <div>
                <div className="mt-6">
                  <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                  Track your fitness
                  </h2>
                  <p className="mt-4 text-lg text-gray-500">
                  Track how many runs you complete and your total distance. Keep yourself accountable and change your program if you're not staying consistent.                  </p>
                  {/* <div className="mt-6">
                    <a href="#" className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700">
                      Get started
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- This example requires Tailwind CSS v2.0+ --> */}
      <div className="py-32 bg-gray-100">
        <div className="max-w-xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
          <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 mb-4">Elevate your run</h2>
          <dl className="space-y-10 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-8">
            <div>
              <div className="mt-5">
                <dt className="text-lg leading-6 font-medium text-gray-500">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                </dt>
                <dt className="mt-2 text-base text-gray-400">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.
                </dt>
                <dd className="mt-2 text-base text-gray-500">
                  Tom · Brisbane, Australia
                </dd>
              </div>
            </div>

            <div>
              <div className="mt-5">
                <dt className="text-lg leading-6 font-medium text-gray-500">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                </dt>
                <dt className="mt-2 text-base text-gray-400">
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.
                </dt>
                <dd className="mt-2 text-base text-gray-500">
                  Patrick · Vancouver, Canada
                </dd>
              </div>
            </div>

            <div>
              <div className="mt-5">
                <dt className="text-lg leading-6 font-medium text-gray-500">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                </dt>
                <dt className="mt-2 text-base text-gray-400">
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.
                </dt>
                <dd className="mt-2 text-base text-gray-500">
                  Patty · Sydney, Australia
                </dd>
              </div>
            </div>
          </dl>
        </div>
      </div>

      {/* <!-- This example requires Tailwind CSS v2.0+ --> */}
      <footer className="bg-gray-100">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
          <div className="flex justify-center space-x-6 md:order-2">
            <a href="https://www.instagram.com/masrunnerapp/" className="text-gray-400 hover:text-gray-500">
              <span className="sr-only">Instagram</span>
              <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                <path fillRule="evenodd" d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z" clipRule="evenodd" />
              </svg>
            </a>
          </div>
          <div className="mt-8 md:mt-0 md:order-1">
          <Link to="/terms" className="text-base text-gray-500 hover:text-gray-900 mr-4">Terms</Link>
          <Link to="/privacy" className="text-base text-gray-500 hover:text-gray-900">Privacy</Link>
            <p className="text-center text-base text-gray-400">
              &copy; MAS Runner, 2021. All rights reserved.
            </p>
          </div>
        </div>
      </footer>

    </div>
  );
}

export default Home;